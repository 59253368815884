import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import AuthApi from "../../api/authApi";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const resetGuid = queryParams.get('ResetGuid');

    const [data, setData] = useState({
        resetGuid: resetGuid || "",
        newPassword: "",
        confirmPassword: "",
        isError: false,
        cp: false
    });
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        setData(prevState => ({
            ...prevState,
            resetGuid: resetGuid || ""
        }));
    }, [resetGuid]);

    const handleResetPassword = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;

        if (form.checkValidity() === true && data.newPassword === data.confirmPassword) {
            try {
                const api = new AuthApi();
                const response = await api.ResetPassword(data);
                if (response.success === true) {
                    toast.success(response.msg);
                    navigate('/login');
                } else {
                    toast.error(response.msg);
                }
            } catch (error) {
                toast.error("Invalid or expired reset token.");
            }
        }

        setValidated(true);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value,
            cp: name === "confirmPassword" ? data.newPassword !== value : prevState.cp
        }));
    };

    return (
        <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth">
                <div className="row flex-grow">
                    <div className="col-lg-4 mx-auto">
                        <div className="auth-form-light text-left p-5">
                            <div className="brand-logo">
                                PDA-Model
                            </div>
                            <h4>Hello! let's get started</h4>
                            <h6 className="font-weight-light">Reset your password.</h6>
                            <Form
                                onSubmit={handleResetPassword}
                                noValidate
                                validated={validated}
                                className="pt-3"
                            >
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Control
                                                required
                                                type="password"
                                                name="newPassword"
                                                placeholder="Enter New Password"
                                                value={data.newPassword}
                                                onChange={handleInputChange}
                                                isInvalid={data.isError}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                New password is required!
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control
                                                required
                                                type="password"
                                                name="confirmPassword"
                                                placeholder="Enter Confirm Password"
                                                value={data.confirmPassword}
                                                onChange={handleInputChange}
                                                isInvalid={data.cp}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Passwords do not match!
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="mt-3">
                                    <Button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">RESET PASSWORD</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
