import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import CategoriesApi from "../../../api/categoriesApi";
import projectApi from "../../../api/projectApi";
import { toast } from 'react-toastify';
import Helpers from "../../../components/Helpers";
import CustomDataTable from '../../../components/CustomDataTabe';
import DeleteModel from "../../../components/DeleteModel";
import CreateProjectModel from "../../../components/CreateProjectModel";
import ProjectContext from '../../../context/projectContext';

const Categories = () => {
    const { projectId, updateProject } = useContext(ProjectContext);
    const { projectDetails, updateProjectDetails } = useContext(ProjectContext);
    const [rows, setRows] = useState([]);
    const [LoadData, setLoadData] = useState(false);
    const [showDeleteCatModal, setShowDeleteCatModal] = useState(false);
    const [categoryId, setCategoryId] = useState("");
    const [validated, setValidated] = useState(false);

    const handleCloseCatModal = () => setShowDeleteCatModal(false);

    const [showProjectModal, setShowProjectModal] = useState(false);
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip">{props}</Tooltip>
    );

    const [data, setData] = useState({
        categoryId: "",
        category1: ""
    });

    useEffect(() => {
        getProjectDetails();
        getList();
    }, [projectId]);

    const getProjectDetails = async () => {
        const Api = new projectApi();
        if (projectId !== null && projectId !== "") {
            let result = await Api.getProjectDetails(projectId);
            if (result) {
                setProjectData(result);
                updateProjectDetails(result);
            }
        }
    };

    const getList = async () => {
        setLoadData(true)
        const Api = new CategoriesApi();
        await Api.getAllCategories(projectId).then(async (response) => {
            if (response && response.length) {
                setRows(response)
                setLoadData(false)
            } else {
                setLoadData(false)
                setRows([])
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        const form = e.currentTarget;
        e.preventDefault()
        if (form.checkValidity()) {
            const Api = new CategoriesApi();
            if (data.categoryId && data.categoryId !== "") {
                // edit category
                data.projectId = projectId
                await Api.editCategory(categoryId, data);
                toast.success("Category updated successfully.");
                clearfield();
                getList();
            } else {
                // add category
                delete data.categoryId;
                data.projectId = projectId
                await Api.addCategory(data);
                setValidated(false);
                toast.success("Category added successfully.");
                clearfield();
                getList();
            }
        } else {
            setValidated(true);
        }
    };

    const clearfield = () => {
        setData({
            categoryId: "",
            category1: ""
        });
        setCategoryId("")
    };

    const CollectData = (item) => {
        setCategoryId(item.categoryId)
        setData(item)
    }

    const DeleteCatData = async () => {
        const Api = new CategoriesApi();
        clearfield();
        await Api.deleteCategory(categoryId).then(response => {
            if (response == undefined) {
                toast.error("This category will not be deleted, blocks are added for it.");
            } else {
                getList();
                setShowDeleteCatModal(false)
                setCategoryId("")
                toast.success("Deleted successfully.");
            }
        }).catch((error) => {
            setShowDeleteCatModal(false)
            setCategoryId("")
            toast.success("Something went wrong.");
        });
    };

    const handleShowDeleteCatModal = (item) => {
        setShowDeleteCatModal(true);
        setCategoryId(item)
    }

    const columns = [
        {
            id: 1,
            name: 'Categories',
            selector: rows => rows.category1,
            cell: (rows) => (
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(rows.category1)}
                >
                    <span>{rows.category1}</span>
                </OverlayTrigger>
            ),
            sortable: true,
            reorder: true,
            tag: "allowRowEvents",
            style: {
                cursor: "text"
            }
        },
        {
            id: 2,
            name: 'Created',
            selector: rows =>
                Helpers.convertUtcToTimezone(rows.createdDate),
            cell: (rows) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(Helpers.convertUtcToTimezone(rows.createdDate, 'MM-DD-YYYY hh:mm:a'))}
                >
                    <span>{rows.createdDate ? Helpers.convertUtcToTimezone(rows.createdDate, 'MM-DD-YYYY') : ""}</span>
                </OverlayTrigger>
            ),
            sortable: true,
            style: {
                cursor: "text"
            }
        },
        {
            id: 3,
            name: 'Action',
            button: true,
            cell: rows => (
                <>
                    <i className="icon-note text-success" onClick={() => CollectData(rows)}></i>
                    <i onClick={() => handleShowDeleteCatModal(rows.categoryId)} className="ml-4 icon-trash text-danger"></i>
                </>
            )
        }
    ];

    const handleShowProjectModal = () => {
        setShowProjectModal(true);
    };

    const handleShowProjectDeleteModal = () => {
        setShowProjectModal(false)
        setShowDeleteProjectModal(true);
    }

    const handleCloseProjectModal = () => {
        setShowProjectModal(false);
        clearProjectData();
    };

    const handleCloseProjectDeleteModal = () => {
        setShowProjectModal(false);
        clearProjectData();
    };

    const [projectData, setProjectData] = useState({
        projectId: "",
        projectName: "",
        clientName: "",
        description: ""
    });

    const clearProjectData = () => {
        let d = { ...projectData }
        d.projectId = ""
        d.projectName = ""
        d.clientName = ""
        d.description = ""
        setProjectData(d)
    };

    const handleProjectChanges = (e) => {
        let { name, value } = e.target;
        setProjectData({
            ...projectData,
            [name]: value
        });
    };

    const saveProjectData = async (e) => {
        e.preventDefault()
        const Api = new projectApi();
        const response = await Api.getAllProjects();
        const projectNameExists = response.some(item => item.projectName === projectData.projectName);
        if (projectData.projectName === "") {
            toast.error("Project name is Required!")
        } else {
            if (projectNameExists) {
                toast.error("Project name already exists!")
            } else {
                // create project
                delete projectData.projectId;
                await Api.addProject(projectData).then(async (response) => {
                    if (response) {
                        setProjectData(response);
                        toast.success("Project created successfully.")
                        updateProject(response.projectId)
                        sessionStorage.setItem("projectId", response.projectId)
                        setShowProjectModal(false);
                        clearProjectData();
                    } else {
                        toast.error("Something went wrong.")
                    }
                })
            }
        }
    };

    const deleteProject = async () => {
        const Api = new projectApi();
        await Api.deleteProject(projectId).then(response => {
            if (response == undefined) {
                toast.error("Please clear the data before deleting the project.");
                setShowDeleteProjectModal(false)
            } else {
                setShowDeleteProjectModal(false)
                toast.success("Deleted successfully.");
                clearProjectData();
                updateProject(null);
                sessionStorage.removeItem('projectId')
            }
        }).catch((error) => {
            setShowDeleteProjectModal(false);
            toast.success("Something went wrong.");
        });
    };

    return (
        <>
            {projectId === "" || projectId === null
                ?
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper pt-4">
                            <div className="row purchace-popup">
                                <div className="col-12 stretch-card grid-margin">
                                    <div className="card card-secondary">
                                        <span className="card-body text-center">
                                            <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={handleShowProjectModal}>Add New Project</span>
                                            <p className="mb-lg-0">Or</p>
                                            <p className="mb-lg-0">Select Project to continue.</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* add/update project modal */}
                    <CreateProjectModel show={showProjectModal} handleClose={handleCloseProjectModal} saveProjectData={saveProjectData} data={projectData} handleChanges={handleProjectChanges} handleShowDeleteModal={handleShowProjectDeleteModal} ></CreateProjectModel>

                    {/* delete project modal */}
                    <DeleteModel show={showDeleteProjectModal} handleClose={handleCloseProjectDeleteModal} DeleteData={deleteProject}></DeleteModel>
                </div>
                :
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper pt-4">
                            <div className="row purchace-popup">
                                <div className="col-12 stretch-card grid-margin">
                                    <div className="card card-secondary">
                                        <span className="card-body d-lg-flex align-items-center py-2">
                                            {projectDetails && projectDetails.clientName && projectDetails.description ?
                                                <p className="mb-lg-0 clientDetails"> <b className='clientDetails'>{projectDetails && projectDetails.clientName && projectDetails.clientName} </b> {projectDetails && projectDetails.clientName && "- "}{projectDetails && projectDetails.description && projectDetails.description}</p>
                                                :
                                                <p className="mb-lg-0 clientDetails"> <b className='clientDetails'>{projectDetails && projectDetails.projectName && projectDetails.projectName} </b></p>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 grid-margin addCat">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Add Category</h4>
                                            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                                                <Col md={10} lg={10} sm={10} xs={10}>
                                                    <Row>
                                                        <Col md={4} lg={4} sm={12} xs={12}>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    name="category1"
                                                                    placeholder="Enter Category Name"
                                                                    value={data.category1}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Category Name is Required!
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} lg={2} sm={12} xs={12}>
                                                            <Button className="catBtn" type="submit" variant="success">
                                                                {categoryId && categoryId !== "" ? "Update" : "Add"}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body categoryTitle">
                                            <div className="d-sm-flex align-items-baseline report-summary-header mb-3">
                                                <h5 className="font-weight-semibold ">Categories</h5>
                                            </div>
                                            <div className="col-lg-6 grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body category">
                                                        <CustomDataTable
                                                            loading={LoadData}
                                                            data={rows}
                                                            columns={columns}
                                                            pointerOnHover={true}
                                                            highlightOnHover={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* delete category */}
                    <DeleteModel show={showDeleteCatModal} handleClose={handleCloseCatModal} DeleteData={DeleteCatData}></DeleteModel>
                </div>
            }
        </>
    );
};

export default Categories;