import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function TimeoutProvider({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const timeoutRef = useRef();

    useEffect(() => {
        if (exemptedRoutes.includes(location.pathname)) return;
        const handleWindowEvents = () => {
            clearTimeout(timeoutRef.current);

            timeoutRef.current = setTimeout(() => {
                // Delete cookies named PDAToken and PDAUser
                document.cookie = "PDAToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "PDAUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                // Navigate to the login page
                navigate('/login');
            }, 43200000);

        };

        // Listen for specific window events to ensure user is still active
        window.addEventListener('mousemove', handleWindowEvents);
        window.addEventListener('keydown', handleWindowEvents);
        window.addEventListener('click', handleWindowEvents);
        window.addEventListener('scroll', handleWindowEvents);

        handleWindowEvents();

        // Cleanup function
        return () => {
            window.removeEventListener('mousemove', handleWindowEvents);
            window.removeEventListener('keydown', handleWindowEvents);
            window.removeEventListener('click', handleWindowEvents);
            window.removeEventListener('scroll', handleWindowEvents);
        };
    }, [navigate, location.pathname]);

    return children;
}

const exemptedRoutes = ['/login', '/resetPassword', '/resetPassword/:ResetGuid'];
