import BaseApi from "./baseApi";
class categoriesApi extends BaseApi {

  async getAllCategories(projectId) {
    return await this.API({
      url: `Project/${projectId}/Categories`,
      method: "get"
    })
  }

  async addCategory(data) {
    return await this.API({
      url: "/categories",
      data: data,
      method: "post"
    })
  }

  async editCategory(categoryId, data) {
    return await this.API({
      url: "/categories/" + categoryId,
      data: data,
      method: "put"
    })
  }

  async deleteCategory(categoryId) {
    return await this.API({
      url: "/categories/" + categoryId,
      method: "delete"
    })
  }

}
export default categoriesApi;