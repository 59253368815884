import React, { useState, useEffect, useContext, useRef } from 'react';
import Xarrow, { useXarrow } from 'react-xarrows';
import Draggable from 'react-draggable';
import { Button, Modal, Form, Row, Col, Table } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { AiFillSetting } from "react-icons/ai";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import categoriesApi from "../../../api/categoriesApi";
import BlockApi from "../../../api/blockApi";
import ConnectionApi from "../../../api/connectionApi";
import reportApi from "../../../api/reportApi";
import projectApi from "../../../api/projectApi";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DeleteModel from "../../../components/DeleteModel";
import { CCollapse } from '@coreui/react';
import CreateProjectModel from "../../../components/CreateProjectModel";
import ProjectContext from '../../../context/projectContext';

const blockStyle = {
    width: "100px",
    alignitems: "center",
    width: "fit-content",
    minWidth: "140px",
    maxWidth: "150px",
    padding: "0px 10px 10px 10px",
    display: "inline-block",
    position: 'fixed',
    zIndex: 999
}

const canvasStyle = {
    display: "block",
    cursor: "pointer",
    height: "44rem",
    width: "128in"
}

const PDAModel = () => {
    const { projectId, updateProject } = useContext(ProjectContext);
    const { projectDetails, updateProjectDetails } = useContext(ProjectContext);
    const [showSettingsPopup, setShowSettingsPopup] = useState(false);
    const [showBlockPopup, setShowBlockPopup] = useState(false);
    const [showReportPopup, setShowReportPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [validated, setValidated] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [connections, setConnections] = useState([]);
    const [activeRow, setActiveRow] = useState(""); // blockID
    const [runPDAMsg, setrunPDAMsg] = useState();
    const [visible, setVisible] = useState(true)
    const [panning, setPanning] = useState(false);

    const [showProjectModal, setShowProjectModal] = useState(false);
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);

    const [blockNumber, setBlockNumber] = useState(0);
    const [blockColor, setBlockColor] = useState("");
    const [recentFourColors, setRecentFourColors] = useState([]);

    const handleClose = () => setShowDeletePopup(false);
    const navigate = useNavigate()

    const updateXarraow = useXarrow();
    const scrollFunc = () => {
        updateXarraow();
    };

    const handleHoverElementMouseEnter = () => {
        setPanning(true);
    };

    const handleHoverElementMouseLeave = () => {
        setPanning(false);
    };

    const [scale, setScale] = useState(1);
    const minScale = 0.4;
    const maxScale = 1.0;
    const wheelTimeout = useRef()

    const handleZoomIn = () => {
        const newScale = scale + 0.1;
        if (newScale <= maxScale) {
            setScale(newScale);
        }
    };

    const handleZoomOut = () => {
        const newScale = scale - 0.1;
        if (newScale >= minScale) {
            setScale(newScale);
        }
    };

    const handleScroll = (event) => {
        if (event.deltaY !== 0) {
            const newScale = event.deltaY > 0 ? scale - 0.1 : scale + 0.1;
            if (newScale >= minScale && newScale <= maxScale) {
                setScale(newScale);
            }
        }
        clearTimeout(wheelTimeout.current)
        wheelTimeout.current = setTimeout(() => {
            wheelTimeout.current = false
        }, 300)
    };

    useEffect(() => {
        const cancelWheel = e => wheelTimeout.current && e.preventDefault()
        document.body.addEventListener('wheel', cancelWheel, { passive: false })
        return () => document.body.removeEventListener('wheel', cancelWheel)
    }, [])

    const handleClickOutside = (event) => {
        if (!event.target.closest('.handle') && !event.target.closest('.btn')) {
            setConnectionData({
                ...connectionData,
                start: null
            });
            setActiveRow("");
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleOnStopPosition = async (dragElement, ui, items) => {
        // set selected block
        let id = items.blockId
        let CategoryName = categoriesList && categoriesList.find(item => item.categoryId === items.categoryId)
        let blockData = {
            projectId: projectId,
            blockId: items.blockId,
            blockNumber: items.blockNumber,
            blockColor: items.blockColor,
            blockName: items.blockName,
            categoryId: items.categoryId,
            duration: items.duration,
            p10: items.p10,
            p90: items.p90,
            a: items.a,
            b: items.b,
            upperBound: items.upperBound,
            lowerBound: items.lowerBound,
            positionX: ui.x,
            positionY: ui.y,
            CategoryName: CategoryName.category1
        }
        const Api = new BlockApi();
        await Api.editBlock(id, blockData);
        getBlocks(categoriesList);
        updateXarraow();
    };

    const [settingsData, setSettingsData] = useState({
        id: "",
        numberOfRuns: "",
        alpha: "",
        beta: "",
        lowerBoundMultiplier: "",
        upperBoundMultiplier: ""
    });

    const clearSettingsData = () => {
        let d = { ...settingsData }
        d.numberOfRuns = ""
        d.alpha = ""
        d.beta = ""
        d.lowerBoundMultiplier = ""
        d.upperBoundMultiplier = ""
        setBlockData(d)
    };

    console.log("blockNumber",blockNumber);
    console.log("blockColor",blockColor);

    const [blockData, setBlockData] = useState({
        blockId: "",
        blockNumber: blockNumber,
        blockColor: blockColor,
        blockName: "",
        categoryId: "",
        duration: "",
        p10: "",
        p90: "",
        positionX: "",
        positionY: "",
        a: "",
        b: "",
        lowerBound: "",
        upperBound: ""
    });

    const clearBlockData = () => {
        let d = { ...blockData }
        d.blockId = ""
        d.blockNumber = blockNumber
        d.blockColor = blockColor
        d.blockName = ""
        d.categoryId = ""
        d.duration = ""
        d.p10 = ""
        d.p90 = ""
        d.positionX = ""
        d.positionY = ""
        d.a = "";
        d.b = "";
        d.lowerBound = "";
        d.upperBound = "";
        setBlockData(d)
    };

    const [connectionData, setConnectionData] = useState({
        id: null,
        start: null,
        end: null
    });

    const clearConnectionData = () => {
        let d = { ...connectionData }
        d.id = null
        d.start = null
        d.end = null
        setConnectionData(d)
    };

    useEffect(() => {
        getCategoriesList();
        getSettings();
        getProjectDetails();
    }, [projectId]);

    useEffect(() => {
        if (connectionData.start !== null && connectionData.end !== null) {
            addConnection();
        }
    }, [connectionData, projectId])

    useEffect(() => {
        handleHoverElementMouseEnter();
        updateXarraow();
        getConnections();
        handleHoverElementMouseLeave();
    }, [scale]);

    const getProjectDetails = async () => {
        const Api = new projectApi();
        if (projectId !== null && projectId !== "") {
            let result = await Api.getProjectDetails(projectId);
            if (result) {
                setProjectData(result);
                updateProjectDetails(result);
            }
        }
    };

    const getCategoriesList = async () => {
        const Api = new categoriesApi();
        await Api.getAllCategories(projectId).then(async (response) => {
            setBlocks([])
            setConnections([])
            if (response && response.length) {
                setCategoriesList(response)
                getBlocks(response);
            } else {
                setCategoriesList([])
                setBlocks([])
                setConnections([])
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const getSettings = async () => {
        const Api = new BlockApi();
        await Api.getSettings(projectId).then(async (response) => {
            if (response) {
                setSettingsData(response)
            } else {
                setSettingsData({})
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const handleSettingsChange = (e) => {
        let { name, value } = e.target;
        setSettingsData({
            ...settingsData,
            [name]: value
        });
    };

    const saveSettings = async (e) => {
        e.preventDefault()
        const Api = new BlockApi();
        await Api.saveSettings(settingsData, projectId).then(async (response) => {
            if (response) {
                setSettingsData(response);
                toast.success("Configuration saved successfully.")
                setShowSettingsPopup(false);
                clearSettingsData();
            } else {
                toast.error("Something went wrong.")
            }
        })
    };

    const getBlocks = async (categoryData) => {
        const Api = new BlockApi();
        await Api.getAllBlocks(projectId).then(async (response) => {
            if (response && response.length) {
                if (categoryData && categoryData.length) {
                    response.map(item1 => {
                        let CategoryName = categoryData && categoryData.find(item2 => item2.categoryId === item1.categoryId)
                        item1['CategoryName'] = CategoryName.category1
                    })
                }
                setBlocks(response)
                getConnections();

                const maxNumber = Math.max(...response.map(block => block.blockNumber), 0);
                setBlockNumber(maxNumber + 1);

                const lastBlock = response[response.length - 1];
                console.log("lastBlock",lastBlock);
                setBlockColor(lastBlock.blockColor);

                const colors = [...new Set(response.map(block => block.blockColor))].slice(-4);
                setRecentFourColors(colors);
            } else {
                setBlocks([])
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const handleBlockChange = (e) => {
        let { name, value } = e.target;
        setBlockData({
            ...blockData,
            [name]: value
        });
    };

    const checkCategories = async () => {
        const Api = new categoriesApi();
        await Api.getAllCategories(projectId).then(async (response) => {
            if (response && response.length == 0) {
                toast.error("Please add category before creating the new block.")
            }
        });
    };

    const addBlock = async (e) => {
        const form = e.currentTarget;
        e.preventDefault()
        if (form.checkValidity()) {
            const Api = new BlockApi();
            if (blockData.a === "" || blockData.a === null) {
                blockData.a = 0
            }
            if (blockData.b === "" || blockData.b === null) {
                blockData.b = 0
            }
            if (blockData.lowerBound === "" || blockData.lowerBound === null) {
                blockData.lowerBound = 0
            }
            if (blockData.upperBound === "" || blockData.upperBound === null) {
                blockData.upperBound = 0
            }
            blockData.projectId = projectId

            if (blockData.blockId && blockData.blockId && blockData.blockId !== "") {
                let id = blockData.blockId
                // edit block
                await Api.editBlock(id, blockData);
                getBlocks(categoriesList);
                toast.success("Updated block successfully.")
                setShowBlockPopup(false);
            } else {
                // add block
                delete blockData.blockId;
                let position = calculateNewBlockPosition();
                blockData.positionY = position.x
                blockData.positionX = position.y
                await Api.addBlock(blockData).then(async (response) => {
                    if (!response.status) {
                        setActiveRow(response.blockId);
                        setConnectionData({
                            ...connectionData,
                            start: "block_" + response.blockId
                        })
                        getBlocks(categoriesList);
                        setBlocks(oldArray => [...oldArray, response]);
                        setValidated(false);
                        toast.success("Added block successfully.")
                        setShowBlockPopup(false);
                        clearBlockData();
                    } else {
                        toast.error(response.message)
                    }
                })
            }
        } else {
            setValidated(true);
        }
    };

    const calculateNewBlockPosition = () => {
        let newX = 20;
        let newY = 20;
        let step = 20;
        let overlap = true;
        while (overlap) {
            overlap = false;
            for (let block of blocks) {
                if (Math.abs(newX - block.positionX) < step && Math.abs(newY - block.positionY) < step) {
                    overlap = true;
                    newX += step;
                    newY += step;
                    break;
                }
            }
        }
        return { x: newX, y: newY };
    };

    const getBlockDetails = async (blockId) => {
        const Api = new BlockApi();
        let result = await Api.getBlockDetails(blockId);
        if (result) {
            setBlockData(result)
        }
    };

    const handleShow = () => {
        setShowDeletePopup(true);
    }

    const deleteBlock = async () => {
        const Api = new BlockApi();
        await Api.deleteBlock(activeRow).then(async (response) => {
            if (response.status === "success") {
                setShowDeletePopup(false);
                getBlocks(categoriesList);
                setActiveRow("")
                clearConnectionData();
                toast.success("Deleted successfully.");
            } else {
                setShowDeletePopup(false);
                toast.error("Something went wrong.");
            }
        })
    };

    const clickBlock = (e, block_blockId, blockId) => {
        e.stopPropagation()
        if (connectionData.start && connectionData.start !== block_blockId) {
            if (e.target.classList.value == 'active') {
                setActiveRow("")
                setConnectionData({
                    ...connectionData,
                    start: null
                })
            } else {
                setActiveRow(blockId)
                setConnectionData({
                    ...connectionData,
                    end: block_blockId
                })
            }
            setActiveRow("")
        } else {
            if (connectionData.start && connectionData.start == block_blockId) {
                setConnectionData({
                    ...connectionData,
                    start: null
                })
                setActiveRow("")
            } else {
                setConnectionData({
                    ...connectionData,
                    start: block_blockId
                })
                setActiveRow(blockId)
            }
        }
    };

    const getConnections = async () => {
        const Api = new ConnectionApi();
        await Api.getAllConnections(projectId).then(async (response) => {
            if (response && response.length) {
                let data = response.map(item => {
                    let obj = {
                        id: item.id,
                        start: item.predecessorId,
                        end: item.blockId
                    }
                    return obj;
                })
                setConnections(data)
            } else {
                setConnections([])
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const addConnection = async () => {
        delete connectionData.id
        let modiData = {
            blockId: connectionData.end.split("_")[1],
            predecessorId: connectionData.start.split("_")[1]
        }
        const Api = new ConnectionApi();
        let result = await Api.addConnection(modiData);
        if (result === undefined) {
            toast.error("Already Connected.");
        } else {
            setConnections([...connections, { id: result.id, start: result.predecessorId, end: result.blockId }])
            clearConnectionData();
            getBlocks(categoriesList);
        }
    }

    const deleteConnection = async (id) => {
        const Api = new ConnectionApi();
        await Api.deleteConnection(id);
        getBlocks(categoriesList);
    };

    const runPDAModel = async () => {
        setrunPDAMsg("Generating Report ...");
        setShowReportPopup(true)
        const Api = new reportApi();
        await Api.runPDA(projectId).then((response) => {
            if (response.status === "success") {
                setShowReportPopup(false)
                navigate('/reports')
            } else {
                setrunPDAMsg(response.message.toString());
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    };

    const handleShowProjectModal = () => {
        setShowProjectModal(true);
    };

    const handleShowProjectDeleteModal = () => {
        setShowProjectModal(false)
        setShowDeleteProjectModal(true);
    }

    const handleCloseProjectModal = () => {
        setShowProjectModal(false);
        clearProjectData();
    };

    const handleCloseProjectDeleteModal = () => {
        setShowProjectModal(false);
        clearProjectData();
    };

    const [projectData, setProjectData] = useState({
        projectId: "",
        projectName: "",
        clientName: "",
        description: ""
    });

    const clearProjectData = () => {
        let d = { ...projectData }
        d.projectId = ""
        d.projectName = ""
        d.clientName = ""
        d.description = ""
        setProjectData(d)
    };

    const handleProjectChanges = (e) => {
        let { name, value } = e.target;
        setProjectData({
            ...projectData,
            [name]: value
        });
    };

    const checkDarkness = (color) => {
        if (!color) {
            return false;
        }
        let c = color.substring(1);
        let rgb = parseInt(c, 16);
        let r = (rgb >> 16) & 0xff;
        let g = (rgb >> 8) & 0xff;
        let b = (rgb >> 0) & 0xff;
        let darkness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return darkness < 120;
    }

    const saveProjectData = async (e) => {
        e.preventDefault()
        const Api = new projectApi();
        const response = await Api.getAllProjects();
        const projectNameExists = response.some(item => item.projectName === projectData.projectName);
        if (projectData.projectName === "") {
            toast.error("Project name is Required!")
        } else {
            if (projectNameExists) {
                toast.error("Project name already exists!")
            } else {
                // create project
                delete projectData.projectId;
                await Api.addProject(projectData).then(async (response) => {
                    if (response) {
                        getProjectDetails();
                        setProjectData(response);
                        updateProjectDetails(response)
                        updateProject(response.projectId)
                        sessionStorage.setItem("projectId", response.projectId)
                        toast.success("Project created successfully.")
                        setShowProjectModal(false);
                        clearProjectData();
                    } else {
                        toast.error("Something went wrong.")
                    }
                })
            }
        }
    };

    const deleteProject = async () => {
        const Api = new projectApi();
        await Api.deleteProject(projectId).then(response => {
            if (response == undefined) {
                toast.error("Please clear the data before deleting the project.");
                setShowDeleteProjectModal(false)
            } else {
                setShowDeleteProjectModal(false)
                toast.success("Deleted successfully.");
                clearProjectData();
                updateProjectDetails(response)
                updateProject(null);
                sessionStorage.removeItem('projectId')
            }
        }).catch((error) => {
            setShowDeleteProjectModal(false);
            toast.success("Something went wrong.");
        });
    };

    return (
        <>
            {projectId === "" || projectId === null
                ?
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper pt-4">
                            <div className="row purchace-popup">
                                <div className="col-12 stretch-card grid-margin">
                                    <div className="card card-secondary">
                                        <span className="card-body text-center">
                                            <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={handleShowProjectModal}>Add New Project</span>
                                            <p className="mb-lg-0">Or</p>
                                            <p className="mb-lg-0">Select Project to continue.</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* add/update project modal */}
                    <CreateProjectModel show={showProjectModal} handleClose={handleCloseProjectModal} saveProjectData={saveProjectData} data={projectData} handleChanges={handleProjectChanges} handleShowDeleteModal={handleShowProjectDeleteModal} ></CreateProjectModel>

                    {/* delete project modal */}
                    <DeleteModel show={showDeleteProjectModal} handleClose={handleCloseProjectDeleteModal} DeleteData={deleteProject}></DeleteModel>
                </div>
                :
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper pt-4">
                            <div className="row purchace-popup">
                                <div className="col-12 stretch-card grid-margin">
                                    <div className="card card-secondary">
                                        <span className="card-body d-lg-flex align-items-center py-2">
                                            {projectDetails && projectDetails.clientName || projectDetails.description ?
                                                <p className="mb-lg-0 clientDetails"> <b className='clientDetails'>{projectDetails.clientName && projectDetails.clientName} </b> {projectDetails.description && "- "}{projectDetails.description && projectDetails.description}</p>
                                                :
                                                <p className="mb-lg-0 clientDetails"> <b className='clientDetails'>{projectDetails && projectDetails.projectName && projectDetails.projectName} </b></p>
                                            }
                                            <button className="btn ml-lg-auto btn-success btn-sm block" onClick={() => { runPDAModel() }}>Run PDA Model</button>
                                            <AiFillSetting className='settingIcon' onClick={() => { setShowSettingsPopup(true); clearSettingsData(); }} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <div className="grid-margin stretch-card">
                                        <div className="card">
                                            <div className={`card-body ${blocks && blocks.length !== 0 ? 'pt-0' : ''}`}>
                                                {blocks && blocks.length !== 0 &&
                                                    <div className='text-right'>
                                                        {visible ?
                                                            <BsArrowRightCircleFill className="arrowIconRight" onClick={() => setVisible(!visible)} aria-expanded={visible}
                                                                aria-controls="collapseWidthExample" />
                                                            :
                                                            <BsArrowLeftCircleFill className="arrowIconLeft" onClick={() => setVisible(!visible)} aria-expanded={visible}
                                                                aria-controls="collapseWidthExample" />
                                                        }
                                                    </div>
                                                }
                                                <div className="row d-sm-flex align-items-baseline mb-3  report-summary-header pb-4">
                                                    <div className="col-md-6">
                                                        <h5 className="font-weight-semibold ">Critical Path Network Diagram</h5>
                                                    </div>
                                                    <div className="col-md-6 text-right">
                                                        <button title="Zoom In" className="w3-tiny w3-button w3-white w3-border" onClick={handleZoomIn}>+</button>
                                                        <button title="Zoom Out" className="w3-tiny w3-button w3-white w3-border" onClick={handleZoomOut}>-</button>
                                                        <button className="btn ml-2 btn-success btn-sm block" disabled={activeRow === "" ? false : true} onClick={() => { checkCategories(); setShowBlockPopup(true); clearBlockData(); }}>Add Block</button>
                                                        <button className="btn ml-2 btn-success btn-sm block" disabled={activeRow && activeRow !== "" ? false : true} onClick={() => { setShowBlockPopup(true); getBlockDetails(activeRow) }}>Edit Block</button>
                                                        <button className="btn ml-2 btn-danger btn-sm block" disabled={activeRow && activeRow !== "" ? false : true} onClick={() => { handleShow() }}>Delete Block</button>
                                                    </div>
                                                </div>
                                                {blocks && blocks.length === 0 ?
                                                    <div className='text-center'>Create Network Diagram and Run PDA Model to view the Report.</div>
                                                    :
                                                    <div style={{ overflow: 'hidden' }} id="hidden-div">
                                                        <Draggable
                                                            handle=".handle1"
                                                            disabled={panning}
                                                        >
                                                            <div className="handle1" style={{ width: '67rem', height: "45rem" }}>
                                                                <div id="zoom-container" className="handle1 zoom-container" style={{ transform: `scale(${scale})`, width: '202rem', height: '101rem' }} onWheel={handleScroll}>
                                                                    <div zoom-content style={canvasStyle} id="canvas" onScroll={scrollFunc} onScrollCapture={() => setPanning(!panning)}>
                                                                        <div style={canvasStyle} id="canvas" onScroll={scrollFunc} onScrollCapture={() => setPanning(!panning)}>
                                                                            {
                                                                                blocks && blocks.map((items) =>
                                                                                    <Draggable
                                                                                        handle=".handle"
                                                                                        onDrag={updateXarraow}
                                                                                        onStop={(dragElement, ui) => {
                                                                                            handleOnStopPosition(dragElement, ui, items)
                                                                                        }}
                                                                                        defaultPosition={{ x: items?.positionX, y: items?.positionY }}
                                                                                        key={`drag-${items.blockId}`}
                                                                                    >
                                                                                        <div
                                                                                            id={"block_" + items.blockId}
                                                                                            className={{ 'handle': true, 'active': activeRow && activeRow == items.blockId, 'text-light': checkDarkness(items.blockColor) }}
                                                                                            style={{ ...blockStyle, background: items.blockColor ? items.blockColor : "#dfe5eb" }}
                                                                                            onClick={(e) => (clickBlock(e, "block_" + items.blockId, items.blockId))}
                                                                                            onMouseEnter={handleHoverElementMouseEnter}
                                                                                            onMouseLeave={handleHoverElementMouseLeave}
                                                                                        >
                                                                                            <div className='row' style={{ borderBottom: "1px solid #ccc" }}>
                                                                                                <div>
                                                                                                    <p style={{ fontSize: '14px', textAlign: 'center', paddingTop: '6px', marginBottom: '6px' }}>
                                                                                                        <b>{items.CategoryName}</b>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <p style={{ fontSize: '14px', paddingTop: '10px', marginBottom: '7px' }}>
                                                                                                <b>{items.blockNumber + "."} </b>
                                                                                                {items.blockName}
                                                                                            </p>
                                                                                            <div className='row'>
                                                                                                <div className='col smallBlock'>
                                                                                                    <p style={{ fontSize: '10px', marginBottom: '0px' }}>DET</p>
                                                                                                    <p style={{ fontSize: '10px' }}>{items.duration}</p>
                                                                                                </div>
                                                                                                <div className='col smallBlock'>
                                                                                                    <p style={{ fontSize: '10px', marginBottom: '0px' }}>P10</p>
                                                                                                    <p style={{ fontSize: '10px' }}>{items.p10}</p>
                                                                                                </div>
                                                                                                <div className='col smallBlock'>
                                                                                                    <p style={{ fontSize: '10px', marginBottom: '0px' }}>P90</p>
                                                                                                    <p style={{ fontSize: '10px' }}>{items.p90}</p>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className='row'>
                                                                                                {items.a && items.a !== 0 ?
                                                                                                    <div className='col smallBlock'>
                                                                                                        <p style={{ fontSize: '10px', marginBottom: '0px' }}>Alpha</p>
                                                                                                        <p style={{ fontSize: '10px' }}>{items.a}</p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }

                                                                                                {items.b && items.b !== 0 ?
                                                                                                    <div className='col smallBlock'>
                                                                                                        <p style={{ fontSize: '10px', marginBottom: '0px' }}>Beta</p>
                                                                                                        <p style={{ fontSize: '10px' }}>{items.b}</p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </div>

                                                                                            <div className='row'>
                                                                                                {items.lowerBound && items.lowerBound !== 0 ?
                                                                                                    <p style={{ fontSize: '12px', marginBottom: '0px' }}>Lower Bound : {items.lowerBound}</p>
                                                                                                    :
                                                                                                    null}
                                                                                                {items.upperBound && items.upperBound !== 0 ?
                                                                                                    <p style={{ fontSize: '12px', marginBottom: '0px' }}>Upper Bound : {items.upperBound}</p>
                                                                                                    :
                                                                                                    null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </Draggable>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    connections && connections.length ? connections.map((items) =>
                                                                        <Xarrow start={`block_` + items.start} end={`block_` + items.end} labels={<i style={{ cursor: "pointer" }} onClick={() => deleteConnection(items.id)}><AiOutlineClose /></i>}></Xarrow>
                                                                    ) : ''
                                                                }
                                                            </div>
                                                        </Draggable>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='blocksTable'>
                                    {blocks && blocks.length !== 0 &&
                                        <CCollapse id="collapseWidthExample" horizontal visible={visible}>
                                            <div className='table-data'>
                                                <Table bordered style={{ marginLeft: '20px', width: '190px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Blocks</th>
                                                            <th>Predecessors</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {blocks && blocks.map(item =>
                                                            <tr className={activeRow && activeRow == item.blockId ? 'active' : ""}>
                                                                <td>{item.blockNumber}</td>
                                                                <td>{item.predecessors && item.predecessors.join(",")}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CCollapse>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* save settings popup */}
                        <Modal show={showSettingsPopup} onHide={() => setShowSettingsPopup(false)}>
                            <Form onSubmit={saveSettings} noValidate>
                                <Modal.Header closeButton>
                                    <Modal.Title >Enter Settings Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Number Of Runs</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        min={1}
                                                        name="numberOfRuns"
                                                        placeholder="Enter Number Of Runs"
                                                        value={settingsData.numberOfRuns}
                                                        onChange={(e) => handleSettingsChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Alpha</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        min={1}
                                                        name="alpha"
                                                        placeholder="Enter alpha"
                                                        value={settingsData.alpha}
                                                        onChange={(e) => handleSettingsChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Beta</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        min={1}
                                                        name="beta"
                                                        placeholder="Enter Beta"
                                                        value={settingsData.beta}
                                                        onChange={(e) => handleSettingsChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Lower Bound Multiplier</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        min={1}
                                                        step=".01"
                                                        name="lowerBoundMultiplier"
                                                        placeholder="Enter Lower Bound Multiplier"
                                                        value={settingsData.lowerBoundMultiplier}
                                                        onChange={(e) => handleSettingsChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Upper Bound Multiplier</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        min={1}
                                                        step=".01"
                                                        name="upperBoundMultiplier"
                                                        placeholder="Upper Lower Bound Multiplier"
                                                        value={settingsData.upperBoundMultiplier}
                                                        onChange={(e) => handleSettingsChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button type="submit" variant="success btn-sm">Save</Button>
                                    <Button variant="danger btn-sm" onClick={() => { setShowSettingsPopup(false); clearSettingsData(); }}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* add/edit block popup */}
                        <Modal show={showBlockPopup} onHide={() => setShowBlockPopup(false)}>
                            <Form onSubmit={addBlock} noValidate validated={validated}>
                                <Modal.Header closeButton>
                                    <Modal.Title >Enter Block Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Block #</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        min={0}
                                                        name="blockNumber"
                                                        placeholder="Enter Block Number"
                                                        value={blockData.blockNumber || blockNumber}
                                                        onChange={(e) => handleBlockChange(e)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Number is Required!
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Label>Block Color</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="blockColor"
                                                    placeholder="Select Color"
                                                    value={blockData.blockColor || blockColor}
                                                    onChange={(e) => handleBlockChange(e)}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label></Form.Label>
                                                <Form.Control
                                                    type="color"
                                                    name="blockColor"
                                                    value={blockData.blockColor}
                                                    onChange={(e) => handleBlockChange(e)}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label>Recently used</Form.Label>
                                                <div style={{ display: 'flex', gap: '5px', marginTop: '4px' }}>
                                                    {recentFourColors.map((color, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                width: '30px',
                                                                height: '30px',
                                                                backgroundColor: color,
                                                                cursor: 'pointer',
                                                                border: blockColor === color ? '2px solid black' : 'none'
                                                            }}
                                                            onClick={() => setBlockColor(color)}
                                                        ></div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="blockName"
                                                        placeholder="Enter Block Name"
                                                        value={blockData.blockName}
                                                        onChange={(e) => handleBlockChange(e)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Name is Required!
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Select Category</Form.Label>
                                                    <Form.Select
                                                        required
                                                        aria-label="Default select example"
                                                        name="categoryId"
                                                        id="categoryId"
                                                        value={blockData.categoryId}
                                                        onChange={(e) => handleBlockChange(e)}
                                                    >
                                                        <option value=''>select category</option>
                                                        {categoriesList && categoriesList.map(items => (
                                                            <option value={items.categoryId}>{items.category1}</option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Category is Required!
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Duration</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        min="0"
                                                        step=".01"
                                                        name="duration"
                                                        placeholder="Enter Duration"
                                                        value={blockData.duration}
                                                        onChange={(e) => handleBlockChange(e)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Duration is Required!
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>P10</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        min="0"
                                                        step=".01"
                                                        name="p10"
                                                        placeholder="Enter p10"
                                                        value={blockData.p10}
                                                        onChange={(e) => handleBlockChange(e)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        P10 is Required!
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>P90</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        min="0"
                                                        step=".01"
                                                        name="p90"
                                                        placeholder="Enter p90"
                                                        value={blockData.p90}
                                                        onChange={(e) => handleBlockChange(e)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        P90 is Required!
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Alpha (Optional)</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        max="99.99"
                                                        step=".01"
                                                        name="a"
                                                        placeholder="Enter Alpha"
                                                        value={blockData.a}
                                                        onChange={(e) => handleBlockChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Beta (Optional)</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        max="99.99"
                                                        step=".01"
                                                        name="b"
                                                        placeholder="Enter Beta"
                                                        value={blockData.b}
                                                        onChange={(e) => handleBlockChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Lower Bound (Optional)</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        max="99.99"
                                                        step=".01"
                                                        name="lowerBound"
                                                        placeholder="Enter Lower Bound"
                                                        value={blockData.lowerBound}
                                                        onChange={(e) => handleBlockChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Upper Bound (Optional)</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        max="99.99"
                                                        step=".01"
                                                        name="upperBound"
                                                        placeholder="Enter Upper Bound"
                                                        value={blockData.upperBound}
                                                        onChange={(e) => handleBlockChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button type="submit" variant="success btn-sm">
                                        {blockData && blockData.blockId && blockData.blockId !== "" ? "Update" : "Add"}
                                    </Button>
                                    <Button variant="danger btn-sm" onClick={() => { setShowBlockPopup(false); clearBlockData(); }}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* generating report popup */}
                        <Modal show={showReportPopup} onHide={() => setShowReportPopup(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title >{runPDAMsg}</Modal.Title>
                            </Modal.Header>
                        </Modal>

                        {/* delete popup */}
                        <DeleteModel show={showDeletePopup} handleClose={handleClose} DeleteData={deleteBlock}></DeleteModel>
                    </div>
                </div>
            }
        </>
    );
};

export default PDAModel;