import BaseApi from "./baseApi";

class reportApi extends BaseApi {

  async runPDA(projectId) {
    return await this.API({
      url: `Project/${projectId}/PDA/Run`,
      method: "get"
    })
  }

  async getReports(projectId) {
    return await this.API({
      url: `Project/${projectId}/Reports`,
      method: "get"
    })
  }

  async getSavedReports(projectId, saveReportId) {
    return await this.API({
      url: `Project/${projectId}/Reports/${saveReportId}`,
      method: "get"
    })
  }

  async saveReport(data) {
    return await this.API({
      url: "Reports/Save",
      data: data,
      method: "post"
    })
  }

  async getReportList(projectId) {
    return await this.API({
      url: `Project/${projectId}/ReportConfiguration`,
      method: "get"
    })
  }

  async saveReportConfiguration(data) {
    return await this.API({
      url: "Reports/ReportConfiguration",
      data: data,
      method: "post"
    })
  }

  async getCustomReports(projectId, savedReportId) {
    const url = savedReportId === -1
      ? `Project/${projectId}/CustomReports/-1`
      : `Project/${projectId}/CustomReports`;
    return await this.API({
      url: url,
      method: "get"
    });
  }

  async deleteReport(reportId) {
    return await this.API({
      url: `Reports/${reportId}`,
      method: "delete"
    })
  }

  async downloadReportConfiguration(projectId, url) {
    return await this.API({
      url: `Project/${projectId}/Csv/${url}`,
      method: "get"
    })
  }

}
export default reportApi;