import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import Reports from "../pages/user/reports/reports";
import PDAModel from "../pages/user/pda-model/pda-model";
import Categories from "../pages/user/category/categories";

const UserRouter = ({logoutUser = null}) => {
    const route = useRoutes([
        { path: '/reports', element: <Reports /> },
        { path: '/pda-model', element: <PDAModel /> },
        { path: "/categories", element: <Categories /> },
        { path: '/*', element: <Navigate to="/pda-model" /> }
    ]);
    return (
        <>
            <Header logoutUser={logoutUser}/>
            {route}
            <Footer />
        </>
    );
};

export default UserRouter;