import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/vendors/simple-line-icons/css/simple-line-icons.css"
import "./assets/css/style.css";
import { CookiesProvider } from "react-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
const Index = () => {
  return (
    <Router>
      <CookiesProvider>
        <App />
        <ToastContainer position="top-right" autoClose={2000} />
      </CookiesProvider>
    </Router>
  );
};
root.render(
  // <React.StrictMode>
    <Index />
  // </React.StrictMode>
);