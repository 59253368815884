import React, { useState, useEffect, useContext } from 'react';
import { Tab, Tabs, Button, Modal, Form, Row, Col } from "react-bootstrap";
import { toast } from 'react-toastify';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import reportApi from "../../../api/reportApi";
import projectApi from "../../../api/projectApi";
import CustomDataTable from '../../../components/CustomDataTabe';
import CreateProjectModel from "../../../components/CreateProjectModel";
import DeleteModel from "../../../components/DeleteModel";
import ProjectContext from '../../../context/projectContext';
import { IoSettingsSharp } from "react-icons/io5";
import moment from 'moment';

const Reports = () => {
    const { projectId, updateProject } = useContext(ProjectContext);
    const { projectDetails, updateProjectDetails } = useContext(ProjectContext);
    const [showProjectModal, setShowProjectModal] = useState(false);
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [showReportConfiModal, setShowReportConfiModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [checked, setChecked] = useState([]);
    const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
    const [reportId, setReportId] = useState("");
    const [LoadData, setLoadData] = useState(false);

    const graphColours = [
        { borderColor: 'rgba(0, 0, 139, 0.8)', backgroundColor: 'rgba(0, 0, 139, 0.6)' },       // Dark Blue
        { borderColor: 'rgba(139, 0, 0, 0.8)', backgroundColor: 'rgba(139, 0, 0, 0.6)' },       // Dark Red
        { borderColor: 'rgba(0, 100, 0, 0.8)', backgroundColor: 'rgba(0, 100, 0, 0.6)' },       // Dark Green
        { borderColor: 'rgba(75, 0, 130, 0.8)', backgroundColor: 'rgba(75, 0, 130, 0.6)' },     // Indigo
        { borderColor: 'rgba(255, 69, 0, 0.8)', backgroundColor: 'rgba(255, 69, 0, 0.6)' },     // Orange Red
        { borderColor: 'rgba(128, 0, 128, 0.8)', backgroundColor: 'rgba(128, 0, 128, 0.6)' },   // Purple
        { borderColor: 'rgba(47, 79, 79, 0.8)', backgroundColor: 'rgba(47, 79, 79, 0.6)' },     // Dark Slate Gray
        { borderColor: 'rgba(0, 128, 128, 0.8)', backgroundColor: 'rgba(0, 128, 128, 0.6)' },   // Teal
        { borderColor: 'rgba(139, 69, 19, 0.8)', backgroundColor: 'rgba(139, 69, 19, 0.6)' },   // Saddle Brown
        { borderColor: 'rgba(25, 25, 112, 0.8)', backgroundColor: 'rgba(25, 25, 112, 0.6)' },   // Midnight Blue
        { borderColor: 'rgba(0, 139, 139, 0.8)', backgroundColor: 'rgba(0, 139, 139, 0.6)' },   // Dark Cyan
        { borderColor: 'rgba(85, 107, 47, 0.8)', backgroundColor: 'rgba(85, 107, 47, 0.6)' },   // Dark Olive Green
        { borderColor: 'rgba(70, 130, 180, 0.8)', backgroundColor: 'rgba(70, 130, 180, 0.6)' }, // Steel Blue
        { borderColor: 'rgba(0, 0, 0, 0.8)', backgroundColor: 'rgba(0, 0, 0, 0.6)' },           // Black
        { borderColor: 'rgba(112, 128, 144, 0.8)', backgroundColor: 'rgba(112, 128, 144, 0.6)' }, // Slate Gray
        { borderColor: 'rgba(128, 128, 0, 0.8)', backgroundColor: 'rgba(128, 128, 0, 0.6)' }    // Olive
    ];

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const [cumulativeDistributionOptions, setCumulativeDistributionOptions] = useState({
        bezierCurve: true,
        tension: 0.2,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Cumulative Distribution Function of Results',
            }
        },
        scales: {
            x: {
                type: 'linear',
                stepSize: 1,
                min: 30,
                max: 50
            }
        },
    });

    const histogramOfSimulationOptions = {
        bezierCurve: true,
        tension: 0.2,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Histogram of Simulation Results (Monte Carlo 1,000 runs)',
            }
        },
    };

    const [cumulativeDistribution, setCumulativeDistribution] = useState([]);
    const [cumulativeDistributionGraphData, setCumulativeDistributionGraphData] = useState({
        labels: [],
        datasets: []
    });

    const [histogramOfSimulation, setHistogramOfSimulation] = useState([]);
    const [histogramOfSimulationGraphData, setHistogramOfSimulationGraphData] = useState({
        labels: [],
        datasets: []
    });

    const [cumulativeDistribution2, setCumulativeDistribution2] = useState([]);
    const [cumulativeDistributionGraphData2, setCumulativeDistributionGraphData2] = useState({
        labels: [],
        datasets: []
    });

    const [histogramOfSimulation2, setHistogramOfSimulation2] = useState([]);
    const [histogramOfSimulationGraphData2, setHistogramOfSimulationGraphData2] = useState({
        labels: [],
        datasets: []
    });

    const [cumulativeDistribution2Columns, setCumulativeDistribution2Columns] = useState([]);
    const [histogramOfSimulation2Columns, setHistogramOfSimulation2Columns] = useState([]);

    const [keyDurations, setKeyDurations] = useState([]);
    const [keyDurationsColumns, setKeyDurationsColumns] = useState([]);

    const [currentKeyDurations, setCurrentKeyDurations] = useState([]);
    const [currentKeyDurationsColumns, setCurrentKeyDurationsColumns] = useState([]);

    const [savedReportKeyDurations, setSavedReportKeyDurations] = useState([]);
    const [savedReportKeyDurationsColumns, setSavedReportKeyDurationsColumns] = useState([]);

    const [reportName, setReportName] = useState("");
    const [reportErrorMsg, setReportErrorMsg] = useState("");
    const [comapreReportErrorMsg, setCompareReportErrorMsg] = useState("");

    useEffect(() => {
        getReportList();
        getProjectDetails();
        getReports();
        getCustomReports();
    }, [projectId]);

    const getProjectDetails = async () => {
        const Api = new projectApi();
        if (projectId !== null && projectId !== "") {
            let result = await Api.getProjectDetails(projectId);
            if (result) {
                setProjectData(result);
                updateProjectDetails(result);
            }
        }
    };

    const getReports = async () => {
        const Api = new reportApi();
        await Api.getReports(projectId).then(response => {
            if (response && response.status === "error") {
                setReportErrorMsg(response.message)
            } else {
                setReportErrorMsg("")
            }
            if (response) {
                setReportName(response.reportName);
            } else {
                setReportName("");
            }
            if (response && response.histogramOfSimulation) {
                setHistogramOfSimulation(response.histogramOfSimulation);
                let modiData = {
                    labels: [],
                    datasets: []
                }
                let current = []
                response.histogramOfSimulation.map(item => {
                    modiData.labels.push(item.bucket);
                    current.push(item.current)
                })
                modiData.datasets.push({
                    label: 'current',
                    data: current,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)'
                });
                setHistogramOfSimulationGraphData(modiData);
            } else {
                setHistogramOfSimulation([])
            }
            if (response && response.cumulativeDistribution) {
                setCumulativeDistribution(response.cumulativeDistribution);
                let arrBucket = [];
                let modiData = {
                    labels: [],
                    datasets: []
                }
                let current = []
                response.cumulativeDistribution.map(item => {
                    arrBucket.push(item.bucket);
                    modiData.labels.push((item.bucket).toFixed(2));
                    current.push(item.currentPercent)
                })
                modiData.datasets.push({
                    label: 'current',
                    data: current,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)'
                });
                let xMin = Math.floor(Math.min(...arrBucket)) - 2;
                let xMax = Math.ceil(Math.max(...arrBucket)) + 2;
                if (xMin % 2 != 0) xMin--;
                if (xMax % 2 != 0) xMax++;
                setCumulativeDistributionOptions(current => {
                    return {
                        ...current, scales: {
                            x: {
                                type: 'linear',
                                stepSize: 1,
                                min: xMin,
                                max: xMax
                            }
                        }
                    }
                });
                setCumulativeDistributionGraphData(modiData);
            } else {
                setCumulativeDistribution([])
            }
            if (response && response.generateKeyDurations) {
                setCurrentKeyDurations(response.generateKeyDurations);
                const keyDurations = response.generateKeyDurations;
                const columns = [];
                const firstReport = Object.keys(keyDurations)[0];
                if (firstReport) {
                    const firstReportData = keyDurations[firstReport];
                    columns.push({
                        name: 'Report Name',
                        selector: row => row.reportName,
                        cell: row => <span>{row.reportName}</span>,
                        sortable: true
                    });

                    for (const [key, value] of Object.entries(firstReportData)) {
                        columns.push({
                            name: key,
                            selector: row => row[key],
                            cell: row => <span>{row[key] !== null ? row[key] : "--"}</span>,
                            sortable: true
                        });
                    }
                }
                const keyDurationsArray = Object.entries(keyDurations).map(([reportName, data]) => ({
                    reportName,
                    ...data
                }));
                setCurrentKeyDurationsColumns(columns);
                setCurrentKeyDurations(keyDurationsArray);
            } else {
                setCurrentKeyDurations([]);
            }
        });
    }

    const getSavedReports = async (reportId) => {
        const Api = new reportApi();
        await Api.getSavedReports(projectId, reportId).then(response => {
            if (response && response.status === "error") {
                setReportErrorMsg(response.message)
            } else {
                setReportErrorMsg("")
            }
            if (response) {
                setReportName(response.reportName);
            } else {
                setReportName("");
            }
            if (response && response.histogramOfSimulation) {
                setHistogramOfSimulation(response.histogramOfSimulation);
                let modiData = {
                    labels: [],
                    datasets: []
                }
                let current = []
                response.histogramOfSimulation.map(item => {
                    modiData.labels.push(item.bucket);
                    current.push(item.current)
                })
                modiData.datasets.push({
                    label: 'current',
                    data: current,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)'
                });
                setHistogramOfSimulationGraphData(modiData);
            } else {
                setHistogramOfSimulation([])
            }
            if (response && response.cumulativeDistribution) {
                setCumulativeDistribution(response.cumulativeDistribution);
                let arrBucket = [];
                let modiData = {
                    labels: [],
                    datasets: []
                }
                let current = []
                response.cumulativeDistribution.map(item => {
                    arrBucket.push(item.bucket);
                    modiData.labels.push((item.bucket).toFixed(2));
                    current.push(item.currentPercent)
                })
                modiData.datasets.push({
                    label: 'current',
                    data: current,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)'
                });
                let xMin = Math.floor(Math.min(...arrBucket)) - 2;
                let xMax = Math.ceil(Math.max(...arrBucket)) + 2;
                if (xMin % 2 != 0) xMin--;
                if (xMax % 2 != 0) xMax++;
                setCumulativeDistributionOptions(current => {
                    return {
                        ...current, scales: {
                            x: {
                                type: 'linear',
                                stepSize: 1,
                                min: xMin,
                                max: xMax
                            }
                        }
                    }
                });
                setCumulativeDistributionGraphData(modiData);
            } else {
                setCumulativeDistribution([])
            }
            if (response && response.generateKeyDurations) {
                setSavedReportKeyDurations(response.generateKeyDurations);
                const keyDurations = response.generateKeyDurations;
                const columns = [];
                const firstReport = Object.keys(keyDurations)[0];
                if (firstReport) {
                    const firstReportData = keyDurations[firstReport];
                    columns.push({
                        name: 'Report Name',
                        selector: row => row.reportName,
                        cell: row => <span>{row.reportName}</span>,
                        sortable: true
                    });

                    for (const [key, value] of Object.entries(firstReportData)) {
                        columns.push({
                            name: key,
                            selector: row => row[key],
                            cell: row => <span>{row[key] !== null ? row[key] : "--"}</span>,
                            sortable: true
                        });
                    }
                }
                const keyDurationsArray = Object.entries(keyDurations).map(([reportName, data]) => ({
                    reportName,
                    ...data
                }));
                setSavedReportKeyDurationsColumns(columns);
                setSavedReportKeyDurations(keyDurationsArray);
            } else {
                setSavedReportKeyDurations([]);
            }
        });
    }

    const handleTabSelect = (key) => {
        if (key.startsWith('report_')) {
            const reportId = key.split('_')[1];
            if (!reportData[reportId]) {
                getSavedReports(reportId);
            }
        } else if (key == "currentReport") {
            getReports();
        } else if (key == "compareReports") {
            getCustomReports();
        }
    };

    const cumulativeDistributionTable = [
        {
            id: 1,
            name: 'Bucket',
            selector: cumulativeDistribution => cumulativeDistribution.bucket,
            cell: (cumulativeDistribution) => (
                <span>{(cumulativeDistribution.bucket !== null ? cumulativeDistribution.bucket : "--").toFixed(2)}</span>
            ),
            sortable: true
        },
        {
            id: 2,
            name: 'Duration',
            selector: cumulativeDistribution => cumulativeDistribution.current,
            cell: (cumulativeDistribution) => (
                <span>{(cumulativeDistribution.current !== null ? cumulativeDistribution.current : "--")}</span>
            ),
            sortable: true
        },
        {
            id: 3,
            name: 'Duration Percentage',
            selector: cumulativeDistribution => cumulativeDistribution.currentPercent,
            cell: (cumulativeDistribution) => (
                <span>{(cumulativeDistribution.currentPercent !== null ? cumulativeDistribution.currentPercent + "%" : "--")}</span>
            ),
            sortable: true
        }
    ];

    const histogramOfSimulationTable = [
        {
            id: 1,
            name: 'Buckets',
            selector: histogramOfSimulation => histogramOfSimulation.bucket,
            cell: (histogramOfSimulation) => (
                <span>{histogramOfSimulation.bucket !== null ? histogramOfSimulation.bucket : "--"}</span>
            ),
            sortable: true
        },
        {
            id: 2,
            name: 'Duration',
            selector: histogramOfSimulation => histogramOfSimulation.current,
            cell: (histogramOfSimulation) => (
                <span>{(histogramOfSimulation.current !== null ? histogramOfSimulation.current : "--")}</span>
            ),
            sortable: true
        }
    ];

    const handleShowProjectModal = () => {
        setShowProjectModal(true);
    };

    const handleShowProjectDeleteModal = () => {
        setShowProjectModal(false)
        setShowDeleteProjectModal(true);
    }

    const handleCloseProjectModal = () => {
        setShowProjectModal(false);
        clearProjectData();
    };

    const handleCloseProjectDeleteModal = () => {
        setShowProjectModal(false);
        clearProjectData();
    };

    const [projectData, setProjectData] = useState({
        projectId: "",
        projectName: "",
        clientName: "",
        description: ""
    });

    const clearProjectData = () => {
        let d = { ...projectData }
        d.projectId = ""
        d.projectName = ""
        d.clientName = ""
        d.description = ""
        setProjectData(d)
    };

    const handleProjectChanges = (e) => {
        let { name, value } = e.target;
        setProjectData({
            ...projectData,
            [name]: value
        });
    };

    const [reportData, setReportData] = useState({
        id: "",
        projectId: "",
        reportName: ""
    });

    const clearReportData = () => {
        let d = { ...reportData }
        d.reportName = ""
        setReportData(d)
    };

    const handleReportChanges = (e) => {
        let { name, value } = e.target;
        setReportData({
            ...reportData,
            [name]: value
        });
    };

    const saveProjectData = async (e) => {
        e.preventDefault()
        const Api = new projectApi();
        const response = await Api.getAllProjects();
        const projectNameExists = response.some(item => item.projectName === projectData.projectName);
        if (projectData.projectName === "") {
            toast.error("Project name is Required!")
        } else {
            if (projectNameExists) {
                toast.error("Project name already exists!")
            } else {
                // create project
                delete projectData.projectId;
                await Api.addProject(projectData).then(async (response) => {
                    if (response) {
                        getProjectDetails();
                        setProjectData(response);
                        toast.success("Project created successfully.");
                        updateProject(response.projectId);
                        sessionStorage.setItem("projectId", response.projectId)
                        setShowProjectModal(false);
                        clearProjectData();
                    } else {
                        toast.error("Something went wrong.");
                    }
                })
            }
        }
    };

    const deleteProject = async () => {
        const Api = new projectApi();
        await Api.deleteProject(projectId).then(response => {
            if (response == undefined) {
                toast.error("Please clear the data before deleting the project.");
                setShowDeleteProjectModal(false)
            } else {
                setShowDeleteProjectModal(false)
                toast.success("Deleted successfully.");
                clearProjectData();
                updateProject(null);
                sessionStorage.removeItem('projectId');
            }
        }).catch((error) => {
            setShowDeleteProjectModal(false);
            toast.success("Something went wrong.");
        });
    };

    const saveReport = async (e) => {
        const form = e.currentTarget;
        e.preventDefault()
        if (form.checkValidity()) {
            const Api = new reportApi();
            delete reportData.id
            reportData.projectId = projectId
            await Api.saveReport(reportData).then(async (response) => {
                if (response.status === "error") {
                    toast.error(response.message);
                } else {
                    setReportName(response.reportName)
                    setProjectData(response);
                    setValidated(false);
                    toast.success("Report saved.");
                    setShowReportModal(false);
                    clearProjectData();
                    getReportList();
                }
            })
        } else {
            setValidated(true);
        }
    };

    const getReportList = async () => {
        const Api = new reportApi();
        await Api.getReportList(projectId).then(async (response) => {
            if (response && response.length) {
                setReportList(response);
                let checkedList = response.filter(item => item.isSelected == true);
                setChecked(checkedList.map(item => item.id));
            } else {
                setReportList([])
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const handleCheck = (e) => {
        const checkedID = Number(e.target.value);
        const value = e.target.checked;
        setChecked((prev) =>
            value
                ? [...prev, checkedID]
                : prev.filter((cur) => cur !== checkedID)
        );
    };

    const updateHistogramOfSimulation2Columns = (newData) => {
        setHistogramOfSimulation2Columns(newData);
        setLoadData(false);
    };

    const updateCumulativeDistribution2Columns = (newData) => {
        setCumulativeDistribution2Columns(newData);
        setLoadData(false);
    };

    const getCustomReports = async (savedReportId) => {
        setLoadData(true);
        const Api = new reportApi();
        await Api.getCustomReports(projectId, savedReportId).then(response => {
            if (response && response.status === "error") {
                setCompareReportErrorMsg(response.message)
            } else {
                setCompareReportErrorMsg("")
            }
            if (response && response.histogramOfSimulation) {
                setHistogramOfSimulation2(response.histogramOfSimulation);
                let arrBucket = [];
                let modiData = {
                    labels: [],
                    datasets: []
                }
                let currentData = [];
                let customReports = {};
                response.histogramOfSimulation.forEach(item => {
                    const { bucket, current, ...rest } = item;
                    arrBucket.push(bucket);
                    modiData.labels.push(bucket);
                    currentData.push(current);
                    for (var prop in rest) {
                        if (customReports[prop]) {
                            customReports[prop].push(rest[prop]);
                        } else {
                            customReports[prop] = [rest[prop]];
                        }
                    }
                })
                modiData.datasets.push({
                    label: 'current',
                    data: currentData,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)'
                });
                let colorIndex = 0
                for (var prop in customReports) {
                    modiData.datasets.push({
                        label: prop,
                        data: customReports[prop],
                        backgroundColor: graphColours[colorIndex] ? graphColours[colorIndex].backgroundColor : 'rgba(255, 99, 132)'
                    });
                    colorIndex++;
                }
                response.histogramOfSimulation.forEach((item, i) => {
                    if (!i) {
                        let data = []
                        for (const property in item) {
                            const capitalizeFirst = property => {
                                return property.charAt(0).toUpperCase() + property.slice(1);
                            };
                            let obj = {
                                id: item.current,
                                name: capitalizeFirst(property),
                                selector: histogramOfSimulation => histogramOfSimulation[property],
                                cell: (histogramOfSimulation) => (
                                    <span>{(histogramOfSimulation[property] !== null ? histogramOfSimulation[property] : "--")}</span>
                                ),
                                sortable: true
                            }
                            data.push(obj)
                        }
                        updateHistogramOfSimulation2Columns(data)
                    }
                })
                setHistogramOfSimulationGraphData2(modiData);
            } else {
                setHistogramOfSimulation2([])
            }
            if (response && response.cumulativeDistribution) {
                setCumulativeDistribution2(response.cumulativeDistribution);
                let arrBucket = [];
                let modiData = {
                    labels: [],
                    datasets: []
                }
                let currentData = [];
                let customReports = {};
                response.cumulativeDistribution.forEach(item => {
                    const { bucket, current, currentPercent, ...rest } = item;
                    arrBucket.push(bucket);
                    modiData.labels.push((bucket).toFixed(2));
                    currentData.push(currentPercent)
                    for (var prop in rest) {
                        if (customReports[prop]) {
                            customReports[prop].push(rest[prop]);
                        } else {
                            customReports[prop] = [rest[prop]];
                        }
                    }
                })
                modiData.datasets.push({
                    label: 'current',
                    data: currentData,
                    borderColor: 'rgb(255, 99, 132, 0.5)',
                    backgroundColor: 'rgba(255, 99, 132)'
                });
                let colorIndex = 0
                for (var prop in customReports) {
                    modiData.datasets.push({
                        label: prop,
                        data: customReports[prop],
                        borderColor: graphColours[colorIndex] ? graphColours[colorIndex].borderColor : 'rgb(255, 99, 132, 0.5)',
                        backgroundColor: graphColours[colorIndex] ? graphColours[colorIndex].backgroundColor : 'rgba(255, 99, 132)'
                    });
                    colorIndex++;
                }
                let xMin = Math.floor(Math.min(...arrBucket)) - 2;
                let xMax = Math.ceil(Math.max(...arrBucket)) + 2;
                if (xMin % 2 != 0) xMin--;
                if (xMax % 2 != 0) xMax++;
                setCumulativeDistributionOptions(current => {
                    return {
                        ...current, scales: {
                            x: {
                                type: 'linear',
                                stepSize: 1,
                                min: xMin,
                                max: xMax
                            }
                        }
                    }
                });
                response.cumulativeDistribution.forEach((item, i) => {
                    item["bucket"] = item.bucket ? item.bucket.toFixed(2) : 0;
                    if (!i) {
                        let data = []
                        for (const property in item) {
                            const capitalizeFirst = property => {
                                return property.charAt(0).toUpperCase() + property.slice(1);
                            };
                            let obj = {
                                id: item.current,
                                name: capitalizeFirst(property),
                                selector: cumulativeDistribution => cumulativeDistribution[property],
                                cell: (cumulativeDistribution) => (
                                    <span>{(cumulativeDistribution[property] !== null ? cumulativeDistribution[property] : "--")}</span>
                                ),
                                sortable: true
                            }
                            data.push(obj)
                        }
                        updateCumulativeDistribution2Columns(data)
                    }
                })
                setCumulativeDistributionGraphData2(modiData);
            } else {
                setCumulativeDistribution2([])
            }
            if (response && response.generateKeyDurations) {
                setKeyDurations(response.generateKeyDurations);
                const keyDurations = response.generateKeyDurations;
                const columns = [];
                const firstReport = Object.keys(keyDurations)[0];
                if (firstReport) {
                    const firstReportData = keyDurations[firstReport];
                    columns.push({
                        name: 'Report Name',
                        selector: row => row.reportName,
                        cell: row => <span>{row.reportName}</span>,
                        sortable: true
                    });

                    for (const [key, value] of Object.entries(firstReportData)) {
                        columns.push({
                            name: key,
                            selector: row => row[key],
                            cell: row => <span>{row[key] !== null ? row[key] : "--"}</span>,
                            sortable: true
                        });
                    }
                }
                const keyDurationsArray = Object.entries(keyDurations).map(([reportName, data]) => ({
                    reportName,
                    ...data
                }));
                setKeyDurationsColumns(columns);
                setKeyDurations(keyDurationsArray);
            } else {
                setKeyDurations([]);
            }
        });
    }

    const saveReportConfiguration = async (e) => {
        e.preventDefault();

        if (checked.length === 0) {
            toast.error("Please select at least one report.");
            return;
        }
        const Api = new reportApi();
        let data = {
            projectId: projectId,
            savedReportIDs: checked
        }
        await Api.saveReportConfiguration(data).then(async (response) => {
            if (response) {
                setShowReportConfiModal(false);
                updateHistogramOfSimulation2Columns();
                updateCumulativeDistribution2Columns();

                // Check the savedReportId and pass it to getCustomReports
                let savedReportId = response.currentReportId ? response.currentReportId.savedReportId : null;
                getCustomReports(savedReportId);
            } else {
                setShowReportConfiModal(false);
                toast.error("Something went wrong.");
            }
        });
    };

    const DeleteReportData = async () => {
        const Api = new reportApi();
        await Api.deleteReport(reportId).then(response => {
            if (response == undefined) {
                setShowDeleteReportModal(false)
                setReportId("")
                toast.error("Report already used so can not delete.");
            } else {
                getReportList();
                setShowDeleteReportModal(false)
                setReportId("")
                toast.success("Deleted successfully.");
            }
        }).catch((error) => {
            setShowDeleteReportModal(false)
            setReportId("")
            toast.success("Something went wrong.");
        });
    };

    const handleCloseReportModal = () => setShowDeleteReportModal(false);

    const handleShowDeleteReportModal = (item) => {
        setShowDeleteReportModal(true);
        setReportId(item);
    }

    const downloadReportConfiguration = async (url) => {
        const Api = new reportApi();
        await Api.downloadReportConfiguration(projectId, url).then(response => {
            const blob = new Blob([response], { type: 'text/csv' });
            const link = document.createElement('a');
            const today = new Date().toISOString().split('T')[0];
            link.href = URL.createObjectURL(blob);
            link.download = `${url}_${today}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            toast.success("Something went wrong.");
        });
    };

    return (
        <>
            {projectId === "" || projectId === null
                ?
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper pt-4">
                            <div className="row purchace-popup">
                                <div className="col-12 stretch-card grid-margin">
                                    <div className="card card-secondary">
                                        <span className="card-body text-center">
                                            <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={handleShowProjectModal}>Add New Project</span>
                                            <p className="mb-lg-0">Or</p>
                                            <p className="mb-lg-0">Select Project to continue.</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* add/update project modal */}
                    <CreateProjectModel show={showProjectModal} handleClose={handleCloseProjectModal} saveProjectData={saveProjectData} data={projectData} handleChanges={handleProjectChanges} handleShowDeleteModal={handleShowProjectDeleteModal} ></CreateProjectModel>

                    {/* delete project modal */}
                    <DeleteModel show={showDeleteProjectModal} handleClose={handleCloseProjectDeleteModal} DeleteData={deleteProject}></DeleteModel>
                </div>
                :
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper pt-4">
                            <div className="row purchace-popup">
                                <div className="col-12 stretch-card grid-margin">
                                    <div className="card card-secondary">
                                        <span className="card-body d-lg-flex align-items-center py-2">
                                            {projectDetails && projectDetails.clientName && projectDetails.description ?
                                                <p className="mb-lg-0 clientDetails"> <b className='clientDetails'>{projectDetails && projectDetails.clientName && projectDetails.clientName} </b> {projectDetails && projectDetails.clientName && "- "}{projectDetails && projectDetails.description && projectDetails.description}</p>
                                                :
                                                <p className="mb-lg-0 clientDetails"> <b className='clientDetails'>{projectDetails && projectDetails.projectName && projectDetails.projectName} </b></p>
                                            }
                                            <a className="btn ml-lg-auto btn-primary btn-sm block" onClick={() => downloadReportConfiguration("PDAData")}>Download PDA Data</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 grid-margin stretch-card">
                                    <div className="card report">
                                        <div className="card-body">
                                            <Tabs
                                                defaultActiveKey="currentReport"
                                                transition={false}
                                                onSelect={handleTabSelect}
                                            >
                                                <Tab eventKey="currentReport" title="Current Report">
                                                    {reportErrorMsg === "" || reportErrorMsg === null ?
                                                        <>
                                                            <div className="row">
                                                                <div className="col-md-12 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <h4 style={{ marginBottom: '20px' }}>
                                                                                    <span>Cumulative Distribution </span>
                                                                                    <a className="btn ml-lg-auto btn-primary btn-sm block" onClick={() => downloadReportConfiguration("CumulativeDistribution")}>Download to CSV</a>
                                                                                </h4>
                                                                                {reportName === "" || reportName === null ?
                                                                                    <Button type="button" style={{ marginBottom: '20px', padding: '10px' }} variant="success btn-sm" onClick={() => { setShowReportModal(true); clearReportData(); }}>Save Report</Button> :
                                                                                    <h4 style={{ marginBottom: '20px' }}>Report Saved as : {reportName}</h4>
                                                                                }
                                                                            </div>
                                                                            <CustomDataTable
                                                                                data={cumulativeDistribution}
                                                                                columns={cumulativeDistributionTable}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <Line
                                                                                options={cumulativeDistributionOptions}
                                                                                data={cumulativeDistributionGraphData}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <h4 style={{ marginBottom: '20px' }}>
                                                                                <span>Histogram of Simulation </span>
                                                                                <a className="btn ml-lg-auto btn-primary btn-sm block" onClick={() => downloadReportConfiguration("HistogramOfSimulation")}>Download to CSV</a>
                                                                            </h4>
                                                                            <CustomDataTable
                                                                                data={histogramOfSimulation}
                                                                                columns={histogramOfSimulationTable}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <Bar
                                                                                options={histogramOfSimulationOptions}
                                                                                data={histogramOfSimulationGraphData}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <Line
                                                                                options={histogramOfSimulationOptions}
                                                                                data={histogramOfSimulationGraphData}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <h4 style={{ marginBottom: '20px' }}>
                                                                                <span>Key Durations</span>
                                                                            </h4>
                                                                            {keyDurations && keyDurations.length ? (<CustomDataTable
                                                                                loading={LoadData}
                                                                                data={currentKeyDurations}
                                                                                columns={currentKeyDurationsColumns}
                                                                            />) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>{reportErrorMsg}</div>
                                                    }
                                                </Tab>
                                                <Tab eventKey="compareReports" title="Compare Reports">
                                                    {comapreReportErrorMsg === "" || comapreReportErrorMsg === null ?
                                                        <>
                                                            <div className="row">
                                                                <div className="col-md-12 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <h4 style={{ marginBottom: '20px' }}>
                                                                                    <span>Cumulative Distribution </span>
                                                                                    <a className="btn ml-lg-auto btn-primary btn-sm block" onClick={() => downloadReportConfiguration("CustomCumulativeDistribution")}>Download to CSV</a>
                                                                                </h4>
                                                                                <button className='btn btn-success' type="button" style={{ marginBottom: '20px', padding: '10px' }} onClick={() => { setShowReportConfiModal(true); clearReportData(); }}>Configuration <IoSettingsSharp /></button>
                                                                            </div>
                                                                            {cumulativeDistribution2Columns && cumulativeDistribution2Columns.length ? (
                                                                                <CustomDataTable
                                                                                    key={`${projectId}-${cumulativeDistribution2.length}-${cumulativeDistribution2Columns.length}`}
                                                                                    loading={LoadData}
                                                                                    data={cumulativeDistribution2}
                                                                                    columns={cumulativeDistribution2Columns}
                                                                                />
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <Line
                                                                                options={cumulativeDistributionOptions}
                                                                                data={cumulativeDistributionGraphData2}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <h4 style={{ marginBottom: '20px' }}>
                                                                                <span>Histogram of Simulation </span>
                                                                                <a className="btn ml-lg-auto btn-primary btn-sm block" onClick={() => downloadReportConfiguration("CustomHistogramOfSimulation")}>Download to CSV</a>
                                                                            </h4>
                                                                            {histogramOfSimulation2Columns && histogramOfSimulation2Columns.length ? (<CustomDataTable
                                                                                key={`${projectId}-${histogramOfSimulation2.length}-${histogramOfSimulation2Columns.length}`}
                                                                                loading={LoadData}
                                                                                data={histogramOfSimulation2}
                                                                                columns={histogramOfSimulation2Columns}
                                                                            />) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <Bar
                                                                                options={histogramOfSimulationOptions}
                                                                                data={histogramOfSimulationGraphData2}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <Line
                                                                                options={histogramOfSimulationOptions}
                                                                                data={histogramOfSimulationGraphData2}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <h4 style={{ marginBottom: '20px' }}>
                                                                                <span>Key Durations</span>
                                                                            </h4>
                                                                            {keyDurations && keyDurations.length ? (<CustomDataTable
                                                                                loading={LoadData}
                                                                                data={keyDurations}
                                                                                columns={keyDurationsColumns}
                                                                            />) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>{comapreReportErrorMsg}</div>
                                                    }
                                                </Tab>
                                                {/* Render saved reports dynamically */}
                                                {reportList &&
                                                    reportList.map((report) => (
                                                        <Tab
                                                            eventKey={`report_${report.id}`}
                                                            title={report.reportName}
                                                        >
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-12 grid-margin stretch-card">
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <h4 style={{ marginBottom: '20px' }}>
                                                                                        <span>
                                                                                            {report.reportName} -{' '}
                                                                                            Cumulative Distribution{' '}
                                                                                        </span>
                                                                                        <a
                                                                                            className="btn ml-lg-auto btn-primary btn-sm block"
                                                                                            onClick={() =>
                                                                                                downloadReportConfiguration(
                                                                                                    report.reportName +
                                                                                                    'CumulativeDistribution'
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Download to CSV
                                                                                        </a>
                                                                                    </h4>
                                                                                </div>
                                                                                <CustomDataTable
                                                                                    data={cumulativeDistribution}
                                                                                    columns={cumulativeDistributionTable}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 grid-margin stretch-card">
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <Line
                                                                                    options={cumulativeDistributionOptions}
                                                                                    data={cumulativeDistributionGraphData}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 grid-margin stretch-card">
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <h4 style={{ marginBottom: '20px' }}>
                                                                                    <span>
                                                                                        {report.reportName} - Histogram of
                                                                                        Simulation{' '}
                                                                                    </span>
                                                                                    <a
                                                                                        className="btn ml-lg-auto btn-primary btn-sm block"
                                                                                        onClick={() =>
                                                                                            downloadReportConfiguration(
                                                                                                report.reportName +
                                                                                                'HistogramOfSimulation'
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Download to CSV
                                                                                    </a>
                                                                                </h4>
                                                                                <CustomDataTable
                                                                                    data={histogramOfSimulation}
                                                                                    columns={histogramOfSimulationTable}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6 grid-margin stretch-card">
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <Bar
                                                                                    options={histogramOfSimulationOptions}
                                                                                    data={histogramOfSimulationGraphData}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 grid-margin stretch-card">
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <Line
                                                                                    options={histogramOfSimulationOptions}
                                                                                    data={histogramOfSimulationGraphData}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 grid-margin stretch-card">
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <h4 style={{ marginBottom: '20px' }}>
                                                                                    <span>Key Durations</span>
                                                                                </h4>
                                                                                {savedReportKeyDurations && savedReportKeyDurations.length ? (<CustomDataTable
                                                                                    loading={LoadData}
                                                                                    data={savedReportKeyDurations}
                                                                                    columns={savedReportKeyDurationsColumns}
                                                                                />) : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </Tab>
                                                    ))}
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* add/edit block popup */}
                    <Modal show={showReportModal} onHide={() => setShowReportModal(false)}>
                        <Form onSubmit={saveReport} noValidate validated={validated}>
                            <Modal.Header>
                                <Modal.Title >Enter Report Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Col md={12} lg={12} sm={12} xs={12}>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Report Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="reportName"
                                                    placeholder="Enter Report Name"
                                                    value={reportData.reportName}
                                                    onChange={(e) => handleReportChanges(e)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Report Name is Required!
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" variant="success btn-sm">Save</Button>
                                <Button variant="danger btn-sm" onClick={() => { setShowReportModal(false); clearReportData(); setValidated(false); }}>
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>

                    {/* configuration report popup */}
                    <Modal show={showReportConfiModal} onHide={() => setShowReportConfiModal(false)}>
                        <Form onSubmit={saveReportConfiguration} noValidate validated={validated}>
                            <Modal.Header>
                                <Modal.Title>Reports</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {reportList && reportList.length ?
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Report Name</th>
                                                <th scope="col">Created At</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={checked.includes(-1)}
                                                        id="-1"
                                                        name="-1"
                                                        value={-1}
                                                        onClick={handleCheck}
                                                    />
                                                </td>
                                                <td>Current Report</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            {reportList.map((item) => (
                                                <tr key={item.id}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={checked.includes(item.id)}
                                                            id={item.id.toString()}
                                                            name={item.id.toString()}
                                                            value={item.id}
                                                            onClick={handleCheck}
                                                        />
                                                    </td>
                                                    <td>{item.reportName}</td>
                                                    <td>{moment(item.createdAt).format("DD-MM-YYYY HH:MM")}</td>
                                                    <td><i onClick={() => handleShowDeleteReportModal(item.id)} className="ml-4 icon-trash text-danger"></i></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    :
                                    <div style={{ textAlign: "center" }}>Please Run PDA modal & save Reports.</div>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" variant="success btn-sm" disabled={reportList && reportList.length > 0 ? false : true}>Generate</Button>
                                <Button variant="danger btn-sm" onClick={() => { setShowReportConfiModal(false); clearReportData(); setValidated(false); }}>
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>

                    {/* delete report */}
                    <DeleteModel show={showDeleteReportModal} handleClose={handleCloseReportModal} DeleteData={DeleteReportData}></DeleteModel>
                </div>
            }
        </>
    );
};
export default Reports;