import React from "react";
import Login from "../pages/auth/login";
import ResetPassword from "../pages/auth/resetPassword";
import { Navigate, useRoutes } from 'react-router-dom'

const AuthRouter = ({ updatedUser }) => {
    const route = useRoutes([
        { path: '/*', element: <Navigate to="/login" /> },
        { path: '/login', element: <Login updateUser={updatedUser} /> },
        { path: '/resetPassword', element: <ResetPassword updateUser={updatedUser} /> },
        { path: '/resetPassword/:ResetGuid', element: <ResetPassword updateUser={updatedUser} /> }
    ])
    return (
        <>
            {route}
        </>
    );
};

export default AuthRouter