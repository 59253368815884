import React from 'react'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
function ChangePasswordModel(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.handleClose}>
        <Form
          className="forms-sample"
          onSubmit={props.changePassword}
          noValidate
          validated={props.validated}
        >
          <Modal.Header>
            <Modal.Title >Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12} lg={12} sm={12} xs={12}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="oldPassword"
                      placeholder="Enter Old Password"
                      value={props.changePassData.oldPassword}
                      onChange={(e) => props.handlePassChanges(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Old Password is Required!
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="newPassword"
                      placeholder="Enter New Password"
                      value={props.changePassData.newPassword}
                      onChange={(e) => props.handlePassChanges(e)}
                      isInvalid={props.isError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      {props.changePassData.newPassword == "" ? (
                        "New password is required!."
                      ) : (
                        <>
                          Your password isn’t strong enough It should be minimum 6
                          characters <br></br> with at least one special and one
                          capital letter.
                        </>
                      )}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="confirmPassword"
                      placeholder="Enter Confirm Password"
                      value={props.changePassData.confirmPassword}
                      onChange={(e) => props.handlePassChanges(e)}
                      isInvalid={props.cp}
                    />
                    <Form.Control.Feedback type="invalid">
                      Password is not matching !
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="success btn-sm" onClick={props.changePassword} disabled={props.cp || props.isError}>Ok</Button>
            <Button variant="secondary btn-sm" onClick={props.handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  )
}

export default ChangePasswordModel