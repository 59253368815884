import BaseApi from "./baseApi";

class ConnectionApi extends BaseApi {

  async getAllConnections(projectId) {
    return await this.API({
      url: `Project/${projectId}/BlockConnections`,
      method: "get"
    })
  }

  async addConnection(data) {
    return await this.API({
      url: "/BlockConnections",
      data: data,
      method: "post"
    })
  }

  async deleteConnection(id) {
    return await this.API({
      url: "/BlockConnections/" + id,
      method: "delete"
    })
  }

}
export default ConnectionApi;