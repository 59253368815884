import React, { useState } from "react";
import ProjectContext from "./projectContext";


const ProjectProvider  = (props) => {
  const { children } = props;
  const [projectId, setProjectId] = useState(null || sessionStorage.getItem('projectId'));
  const [projectLength, setProjectLength] = useState(0);
  const [projectDetails, setProjectDetails] = useState({});

  const updateProject = (projectId) => {
    setProjectId(projectId)
  }

  const updateProjectDetails = (projectDetails) => {
    setProjectDetails(projectDetails)
  }

  const providerData = {
    projectId,
    updateProject,
    projectLength,
    setProjectLength,
    updateProjectDetails,
    projectDetails,
    setProjectDetails
  };

  return (
    <ProjectContext.Provider value={providerData}>
      {children}
    </ProjectContext.Provider>
  )
}

export default ProjectProvider ;