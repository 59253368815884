import BaseApi from "./baseApi";

class AuthApi extends BaseApi {

  async Login(data) {
    return await this.API({
      url: "Auth/Login",
      data: data,
      method: "post"
    })
  }
  async ForgotPassword(email) {
    return await this.API({
      url: "Auth/ForgotPassword?email=" + email,
      method: "post"
    });
  }

  async ResetPassword(data) {
    return await this.API({
      url: "Auth/ResetPassword",
      data: data,
      method: "post"
    })
  }
}
export default AuthApi;