import BaseApi from "./baseApi";

class ProjectApi extends BaseApi {

  async getAllProjects() {
    return await this.API({
      url: "/Projects",
      method: "get"
    })
  }

  async addProject(data) {
    return await this.API({
      url: "/Projects",
      data: data,
      method: "post"
    })
  }

  async getProjectDetails(projectId) {
    return await this.API({
      url: `Projects/${projectId}`,
      method: "get"
    })
  }

  async editProject(projectId, data) {
    return await this.API({
      url: `Projects/${projectId}`,
      data: data,
      method: "put"
    })
  }

  async deleteProject(projectId) {
    return await this.API({
      url: `Projects/${projectId}`,
      method: "delete"
    })
  }

  async changePassword(data) {
    return await this.API({
      url: "Projects/ChangePassword",
      data: data,
      method: "post"
    })
  }

}
export default ProjectApi;