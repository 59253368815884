import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import AuthApi from "../../api/authApi";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();

    const [data, setData] = useState({
        email: "",
        password: ""
    });
    const [validated, setValidated] = useState(false);
    const [cookies, setCookie] = useCookies(['PDAToken', 'PDAUser']);
    const [showResetPassword, setShowResetPassword] = useState(false);

    const handleLoginSubmit = async (event) => {
        let api = new AuthApi();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            await api.Login(data).then((response) => {
                if (response.success) {
                    setCookie(
                        'PDAToken',
                        response.token,
                        {
                            path: '/',
                            maxAge: 60 * 60 * 100,
                            expires: new Date(Date.now() + 60 * 60 * 100),
                        }
                    );
                    setCookie('PDAUser', response, {
                        path: '/',
                        maxAge: 60 * 60 * 100,
                        expires: new Date(Date.now() + 60 * 60 * 100),
                    });
                    navigate('/pda-model');
                    toast.success(response.msg);
                } else {
                    toast.error(response.msg);
                }
            }).catch((error) => {
                toast.error("Email and Password is mismatched.");
            })
        } else {
            toast.error("User Name and Password is required");
        }
        setValidated(true);
    }

    const handleResetPasswordSubmit = async (event) => {
        event.preventDefault();
        if (data.email) {
            let authApi = new AuthApi();
            await authApi.ForgotPassword(data.email)
                .then((response) => {
                    if (response.success === true) {
                        toast.success(response.msg);
                    } else {
                        toast.error(response.msg);
                    }
                })
                .catch((error) => {
                    toast.error(error);
                })
                setShowResetPassword(false);
        }
    }

    const handleChanges = async (e) => {
        let { name, value } = e.target;
        let d = { ...data };
        d[name] = value;
        setData(d);
    };

    return (
        <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth">
                <div className="row flex-grow">
                    <div className="col-lg-4 mx-auto">
                        <div className="auth-form-light text-left p-5">
                            <div className="brand-logo">
                                PDA-Model
                            </div>
                            <h4>Hello! let's get started</h4>
                            <h6 className="font-weight-light">Sign in to continue.</h6>
                            <Form
                                onSubmit={handleLoginSubmit}
                                noValidate
                                validated={validated}
                                className="pt-3"
                            >
                                <Form.Group >
                                    <Form.Control
                                        required
                                        type="text"
                                        name="email"
                                        id=""
                                        placeholder="Email"
                                        value={data.email}
                                        onChange={(e) => handleChanges(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Email is required!
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <br />
                                <Form.Group>
                                    <Form.Control
                                        required
                                        type="password"
                                        name="password"
                                        id="exampleInputPassword"
                                        placeholder="Password"
                                        value={data.password}
                                        onChange={(e) => handleChanges(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Password is required!
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <a onClick={() => setShowResetPassword(true)} style={{float: 'right'}}>Forgot Password</a>
                                <br />
                                <br />
                                <div className="d-flex justify-content-between align-items-center">
                                    <Button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">SIGN IN</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <Modal show={showResetPassword} onHide={() => setShowResetPassword(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Reset Password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleResetPasswordSubmit}>
                                <Form.Group>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        name="email"
                                        value={data.email}
                                        onChange={(e) => handleChanges(e)}
                                        required
                                    />
                                </Form.Group>
                                <br />
                                <Button variant="primary" type="submit">
                                    Send Reset Link
                                </Button>
                            </Form>
                            <p className="mt-2">A reset link will be sent to your email. Please check your inbox (and spam/junk folder) for further instructions on resetting your password.</p>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
        </div>
    );
};

export default Login;
