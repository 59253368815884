import BaseApi from "./baseApi";
class BlockApi extends BaseApi {

  async getAllBlocks(projectId) {
    return await this.API({
      url: `Project/${projectId}/Blocks`,
      method: "get"
    })
  }

  async addBlock(data) {
    return await this.API({
      url: "/Blocks",
      data: data,
      method: "post"
    })
  }

  async getBlockDetails(blockId) {
    return await this.API({
      url: "/Blocks/" + blockId,
      method: "get"
    })
  }

  async editBlock(blockId, data) {
    return await this.API({
      url: "/Blocks/" + blockId,
      data: data,
      method: "put"
    })
  }

  async deleteBlock(blockId) {
    return await this.API({
      url: "/Blocks/" + blockId,
      method: "delete"
    })
  }

  async getSettings(projectId) {
    return await this.API({
      url: `Project/${projectId}/Settings`,
      method: "get"
    })
  }

  async saveSettings(data, projectId) {
    return await this.API({
      url: `Project/${projectId}/Settings`,
      data: data,
      method: "post"
    })
  }
}
export default BlockApi;