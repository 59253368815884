import React from "react";

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                    <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                        Copyright © {new Date().getFullYear()}
                    </span>
                </div>
            </footer>
        </>
    );
};

export default Footer;