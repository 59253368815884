import React from 'react'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
function CreateProjectModel(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title >Enter Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col md={12} lg={12} sm={12} xs={12}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="projectName"
                    placeholder="Enter Project Name"
                    value={props.data.projectName}
                    onChange={(e) => props.handleChanges(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Project name is Required!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Client Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="clientName"
                    placeholder="Enter Client Name"
                    value={props.data.clientName}
                    onChange={(e) => props.handleChanges(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    placeholder="Enter Description"
                    value={props.data.description}
                    onChange={(e) => props.handleChanges(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          {props.data.projectId === "" || props.data.projectId === null ?
            ""
            :
            <Button type="button" variant="danger btn-sm mr-auto" onClick={() => props.handleShowDeleteModal()}>Delete Project</Button>
          }
          <Button type="button" variant="success btn-sm" onClick={props.saveProjectData}>{props.data.projectId === '' ? "Create" : "Update"}</Button>
          <Button variant="secondary btn-sm" onClick={props.handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CreateProjectModel