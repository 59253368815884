import React from 'react';
import IndexRouter from "./routers";
import ProjectProvider from "./context/projectProvider";
import { TimeoutProvider } from './components/SessionTimeoutProvider';


const App = () => {

  return (
    <>
    <TimeoutProvider>
      <ProjectProvider>
        <IndexRouter />
      </ProjectProvider>
      </TimeoutProvider>
    </>
  );
};

export default App;