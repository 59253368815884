import React, { useState, useEffect } from "react";
import AuthRouter from './authRouter'
import UserRouter from './userRouter'
import { Cookies } from "react-cookie";
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";

const IndexRouter = () => {
    const cookies = new Cookies()
    const [userRole, setUserRole] = useState("");
    const navigate = new useNavigate();
    useEffect(() => { }, [userRole])

    const checkFunciton = () => {
        if (cookies.get('PDAToken')) {
            let data = cookies.get('PDAToken')
            if (data && data !== "") {
                return true
            } else {
                return false
            }
        } else {
            return false;
        }
    }
    const updateuser = (data) => {
        setUserRole(data);
    };

    const logoutUser = async () => {
        setUserRole("");
        cookies.remove('PDAToken', { path: '/' });
        cookies.remove('PDAUser', { path: '/' });
        navigate('/');
        toast.success("Loggedout successfully.");
    }

    return (
        <>
            {(checkFunciton()) ? (
                <UserRouter logoutUser={logoutUser} />
            ) : (<AuthRouter updatedUser={updateuser} />)}
        </>
    )
}
export default IndexRouter;