import React, { useState, useEffect, useContext } from 'react';
import { Nav, NavDropdown, Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import projectApi from "../api/projectApi";
import { toast } from 'react-toastify';
import ProjectContext from '../context/projectContext';
import DeleteModel from "../components/DeleteModel";
import CreateProjectModel from "../components/CreateProjectModel";
import ChangePasswordModel from "../components/ChangePasswordModel";
import { Cookies } from "react-cookie";

const Header = ({ logoutUser = null }) => {
    const { projectId, updateProject } = useContext(ProjectContext);
    const { projectDetails, updateProjectDetails } = useContext(ProjectContext);
    const [showProjectModal, setShowProjectModal] = useState(false);
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [cp, setCp] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        getProjects();
        getProjectDetails();
    }, [projectId]);

    const handleShowProjectModal = () => {
        clearData();
        setShowProjectModal(true);
    };

    const handleChangePasswordModal = () => {
        clearData();
        setShowChangePasswordModal(true);
    };

    const handleShowDeleteModal = () => {
        setShowProjectModal(false)
        setShowDeleteProjectModal(true);
    }

    const handleCloseProjectModal = () => {
        setShowProjectModal(false);
        clearData();
    };

    const handleCloseModal = () => {
        setShowDeleteProjectModal(false);
    };

    const [data, setData] = useState({
        projectId: "",
        projectName: "",
        clientName: "",
        description: ""
    });

    const [changePassData, setChangePassData] = useState({
        userId: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });

    const clearData = () => {
        let d = { ...data }
        d.projectId = ""
        d.projectName = ""
        d.clientName = ""
        d.description = ""
        setData(d)
    };

    const clearPassData = () => {
        let d = { ...changePassData }
        d.oldPassword = ""
        d.newPassword = ""
        d.confirmPassword = ""
        setChangePassData(d)
    };

    const handleChanges = (e) => {
        let { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handlePassChanges = (e) => {
        let { name, value } = e.target;
        const d = { ...changePassData };
        d[name] = value;
        if (name == "newPassword") {
            let regex = /[!@#\$%\^&\*,_ -. +, =,!, %, *, &, ”, :, .,]/g;
            const foundSpecialChar = value.match(regex);
            regex = /[0-9]/g;
            const foundNum = value.match(regex);
            regex = /[A-Z]/g;
            const foundUppercase = value.match(regex);
            regex = /[a-z]/g;
            const lowerCase = value.match(regex);
            regex = /[~,\,<, ,]/g;
            const isSpace = value.match(regex);
            if (
                value?.length > 8 &&
                foundUppercase?.length &&
                foundSpecialChar?.length &&
                foundNum?.length &&
                lowerCase?.length &&
                !isSpace?.length
            ) {
                setIsError(false);
            } else {
                setIsError(true);
            }
        }
        if (name == "confirmPassword") {
            if (d.confirmPassword != d.newPassword) {
                setCp(true);
            } else {
                setCp(false);
            }
        } else if (d.confirmPassword) {
            if (d.confirmPassword != d.newPassword) {
                setCp(true);
            } else {
                setCp(false);
            }
        }
        setChangePassData(d);
    };

    const saveProjectData = async (e) => {
        e.preventDefault()
        const Api = new projectApi();
        const response = await Api.getAllProjects();
        const projectNameExists = response.some(item => item.projectName === data.projectName);
        if (data.projectName === "") {
            toast.error("Project name is Required!")
        } else {
            if (data.projectId && data.projectId && data.projectId !== "") {
                // update project
                await Api.editProject(projectId, data).then(async (response) => {
                    getProjectDetails();
                    setData(response);
                    updateProjectDetails(response);
                    toast.success("Updated Project successfully.");
                    setShowProjectModal(false);
                    clearData();
                    getProjects();
                })
            } else {
                // create project
                if (projectNameExists) {
                    toast.error("Project name already exists!")
                } else {
                    delete data.projectId;
                    await Api.addProject(data).then(async (response) => {
                        if (response) {
                            getProjectDetails();
                            setData(response);
                            updateProjectDetails(response);
                            updateProject(response.projectId);
                            sessionStorage.setItem("projectId", response.projectId);
                            toast.success("Project created successfully.");
                            setShowProjectModal(false);
                            clearData();
                            getProjects();
                        } else {
                            toast.error("Something went wrong.")
                        }
                    })
                }
            }
        }
    };

    const changePassword = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            const Api = new projectApi();
            let cookies = new Cookies();
            let userData = cookies.get('PDAUser');
            let d = { ...changePassData, userId: userData.userId }
            delete d.confirmPassword;
            Api.changePassword(d ).then((res) => {
                if (res.success) {
                    setValidated(false);
                    toast.success(res.msg);
                    clearPassData();
                } else {
                    toast.error(res.msg);
                }
            })
                .catch((error) => {
                    toast.error(error);
                });
        }
    };

    const getProjects = async () => {
        const Api = new projectApi();
        await Api.getAllProjects().then(async (response) => {
            if (response && response.length) {
                setProjectList(response)
            } else {
                setProjectList([])
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const getProjectDetails = async () => {
        const Api = new projectApi();
        let result = await Api.getProjectDetails(projectId);
        if (result) {
            setData(result);
            updateProjectDetails(result);
        }
    };

    const deleteProject = async () => {
        const Api = new projectApi();
        await Api.deleteProject(projectId).then(response => {
            if (response == undefined) {
                toast.error("Please clear the data before deleting the project.");
                setShowDeleteProjectModal(false)
            } else {
                getProjects();
                setShowDeleteProjectModal(false)
                toast.success("Deleted successfully.");
                clearData();
                updateProject(null);
                sessionStorage.removeItem('projectId')

            }
        }).catch((error) => {
            setShowDeleteProjectModal(false);
            toast.success("Something went wrong.");
        });
    };

    const navDropdownTitle = (
        <a className="nav-link">
            <i className="icon-user mr-2"></i>
            <h5 className="mb-0 font-weight-medium">Admin</h5>
        </a>
    );

    return (
        <>
            <Nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="navbar-brand-wrapper d-flex align-items-center">
                    <a className="navbar-brand brand-logo" href="/pda-model">
                        <div className="brand-logo">
                            PDA-Model
                        </div>
                    </a>
                    <a className="navbar-brand brand-logo-mini" href="">PDA-Model</a>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-center flex-grow-1">
                    <ul className="navbar-nav navbar-nav-right ml-auto">
                        <li className="nav-item">
                            <Form.Select
                                aria-label="Default select example"
                                name="projectName"
                                id="projectName"
                                value={projectId || sessionStorage.getItem('projectId')}
                                onChange={(e) => {
                                    updateProject(e.target.value)
                                    sessionStorage.setItem('projectId', e.target.value)
                                }}
                            >
                                <option value=''>Select Project</option>
                                {projectList && projectList.map(items => (
                                    <option value={items.projectId || sessionStorage.getItem('projectId')}>{items.projectName}</option>
                                ))}
                            </Form.Select>
                        </li>
                        <li className="nav-item">
                            <Button className='addProjectbtn' variant="outline-secondary" size="sm" onClick={handleShowProjectModal}>+</Button>
                        </li>
                        <li className="nav-item">
                            <Link to="/pda-model">
                                <a className="nav-link">
                                    <i className="icon-grid mr-2"></i>
                                    <h5 className="mb-0 font-weight-medium">PDA Model</h5>
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/reports">
                                <a className="nav-link">
                                    <i className="icon-chart mr-2"></i>
                                    <h5 className="mb-0 font-weight-medium">PDA Report</h5>
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/categories">
                                <a className="nav-link">
                                    <i className="icon-list mr-2"></i>
                                    <h5 className="mb-0 font-weight-medium">Categories</h5>
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item dropdown d-block d-xl-inline-flex user-dropdown">
                            <NavDropdown title={navDropdownTitle} drop="start">
                                <NavDropdown.Item href="/reports"><i className="dropdown-item-icon icon-chart text-primary"></i> PDA Report</NavDropdown.Item>
                                <NavDropdown.Item href="/pda-model"><i className="dropdown-item-icon icon-grid text-primary"></i> PDA Model</NavDropdown.Item>
                                <NavDropdown.Item href="/categories"><i className="dropdown-item-icon icon-list text-primary"></i> Categories</NavDropdown.Item>
                                {projectId === "" || projectId === null ?
                                    ''
                                    :
                                    <NavDropdown.Item href="#" onClick={() => { setShowProjectModal(true); getProjectDetails() }}><i className="dropdown-item-icon icon-pencil text-primary"></i> Edit Project</NavDropdown.Item>
                                }
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#" onClick={handleChangePasswordModal}><i className="dropdown-item-icon icon-lock text-primary"></i>Change Password</NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={() => logoutUser()}><i className="dropdown-item-icon icon-power text-primary"></i>Sign Out</NavDropdown.Item>
                            </NavDropdown>
                        </li>
                    </ul>
                </div>
                {/* add/update project modal */}
                <CreateProjectModel show={showProjectModal} handleClose={handleCloseProjectModal} saveProjectData={saveProjectData} data={data} handleChanges={handleChanges} handleShowDeleteModal={handleShowDeleteModal} ></CreateProjectModel>

                {/* change password modal */}
                <ChangePasswordModel show={showChangePasswordModal} handleClose={() => setShowChangePasswordModal(false)} changePassword={changePassword} changePassData={changePassData} handlePassChanges={handlePassChanges} validated={validated} cp={cp} isError={isError}></ChangePasswordModel>

                {/* delete project modal */}
                <DeleteModel show={showDeleteProjectModal} handleClose={handleCloseModal} DeleteData={deleteProject}></DeleteModel>
            </Nav>
        </>
    );
};

export default Header;
