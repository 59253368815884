import React from 'react'
import { Button, Modal } from "react-bootstrap";
function DeleteModel(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title >DELETE!!</Modal.Title>
        </Modal.Header>
        <div className='Delete_img'>
        </div>
        <Modal.Body>Are you sure you want to Delete ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger btn-sm" onClick={props.DeleteData}>
            Delete
          </Button>
          <Button variant="secondary btn-sm" onClick={props.handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DeleteModel